import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'
import * as styles from './CardModule.module.scss'

import { Heading } from '../../typography/Heading'
import { RichText } from '../../rich-text'
import { Container, Row, Col } from '../../grid'

import { Card } from './components'

// TODO: We support quite a few variants of the card module where all of them are different from
// one another. However, all of these different variants use this component under the hood
// and it got to a point where it's impossible to manage. We need to break it down and make sure
// that one module type maps to one module component (doesn't matter that it looks similar, we
// componentized the children anyway).

const CardModule = ({
  richHeadline,
  richBody,
  backgroundColor,
  cardModules,
  columns,
  centered,
  headerLevel = 2,
  blueHeader,
}) => {
  // Determine appropriate card width for the layout
  let columnWidth = 6
  switch (columns) {
    case 2:
      columnWidth = 6
      break
    case 3:
      columnWidth = 4
      break
    default:
      columnWidth = 12
      break
  }

  // Split the modules into chunks of the size of the column count
  const moduleRows = chunk(cardModules, columns)

  const headlineClasses = classNames(
    styles.headline,
    centered && styles.centered,
    blueHeader && styles.blueHeader
  )
  // If centered, use the whole width to display the title, otherwise, constraint to right 2/3s
  const headlineColWidth = centered ? 12 : 8

  const bodyClasses = classNames(styles.body, centered && styles.centered)

  /**
   * We're establishing an ascending heading level here
   *
   * 1. CardModule renders an optional module headline and then
   *   each card renders eyebrow
   * 2. The difference between semantic levels should be exactly
   *   one, i.e. if the module headline uses h1, eyebrow should
   *   use h2. If module headline uses h2, eyebrow should use h3, etc.
   * 3. I didn't want to keep a long list of disconnected if conditions
   *   b/c all heading levels ultimately depend on the module headline
   *   semantic level if you know what I mean. This is our canonical
   *   source that we use to compute all other levels.

   * Even though the minimum value for headerLevel is 1, moduleHeadlineLevel can never be 0
   * because we conditionally render the headline. That said, moduleHeadlineLevel is only used
   * as a reference value for the cardHeadlineLevel in that case and not used directly.
  */
  const moduleHeadlineLevel = richHeadline ? headerLevel : headerLevel - 1
  const cardHeadlineLevel = moduleHeadlineLevel + 1

  const sectionClasses = backgroundColor
    ? classNames(styles.sectionContainer, styles.sectionContainerWhite)
    : styles.sectionContainer

  const showBoxShadow = !backgroundColor

  return (
    <div className={sectionClasses}>
      <Container data-qa="card-module-container">
        {richHeadline && (
          <Row>
            <Col xs={12} md={headlineColWidth}>
              <RichText
                overrides={{
                  paragraph: (node, children) =>
                    !isEmpty(children) && (
                      <Heading
                        element={`h${moduleHeadlineLevel}`}
                        size={2}
                        className={headlineClasses}
                        data-qa="card-module-title"
                      >
                        {children}
                      </Heading>
                    ),
                }}
              >
                {richHeadline}
              </RichText>
              {richBody && (
                <div className={bodyClasses} data-qa="card-module-body">
                  <RichText>{richBody}</RichText>
                </div>
              )}
            </Col>
          </Row>
        )}

        {moduleRows.map((rows, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row className={styles.cardsWrapper} key={index} data-qa="card-module-repeater">
            {rows.map(card => (
              <Card
                key={card.id}
                {...card}
                columnWidth={columnWidth}
                centered={centered}
                eyebrowElement={`h${cardHeadlineLevel}`}
                taglineElement={!card.richHeadline ? `h${cardHeadlineLevel}` : `p`}
                showBoxShadow={showBoxShadow}
              />
            ))}
          </Row>
        ))}
      </Container>
    </div>
  )
}

CardModule.propTypes = {
  cardModules: PropTypes.arrayOf(PropTypes.object).isRequired,
  richHeadline: PropTypes.object,
  richBody: PropTypes.object,
  // First module heading level
  // (it's module's responsibility to maintain ascending heading level if it uses more h tags)
  headerLevel: PropTypes.oneOf([1, 2]),
  columns: PropTypes.oneOf([2, 3]).isRequired,
  centered: PropTypes.bool,
  backgroundColor: PropTypes.string,
  blueHeader: PropTypes.bool,
}

export { CardModule }
export default CardModule
