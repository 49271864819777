/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import * as styles from './TitleModule.module.scss'
import { Container } from '../../grid'

import { RichText } from '../../rich-text'
import { Heading } from '../../typography/Heading'
import { Button } from '../../button'
import { Eyebrow } from '../../typography/Eyebrow'

const TitleModule = ({
  richHeadline,
  richSubHeadline,
  richTagline,
  backgroundColor,
  backgroundImage,
  buttonUrl,
  buttonCta,
  buttonAlt,
  linkTextCta,
  linkTextUrl,
  linkTextAlt,
  fontColor,
  customBackgroundColor,
}) => {
  let selectedFontColor
  switch (fontColor) {
    case 'light-blue':
      selectedFontColor = 'lightBlue'
      break
    case 'ice-blue':
      selectedFontColor = 'iceBlue'
      break
    case 'white':
      selectedFontColor = 'white'
      break
    case 'black':
    default:
      selectedFontColor = 'black'
  }

  const taglineClasses = classNames(styles.tagline, styles[selectedFontColor])
  const headingClasses = classNames(styles.heading, styles[selectedFontColor])
  const bodyClasses = classNames(styles.body, styles[selectedFontColor])
  const linkTextClasses = classNames(styles.linkText, styles[selectedFontColor])

  const style = {}

  let topStyle = styles.TitleModule

  if (backgroundColor) {
    topStyle =
      backgroundColor === 'personal'
        ? classNames(styles.TitleModule, styles.Personal)
        : classNames(styles.TitleModule, styles.Business)
  }

  if (backgroundColor === 'other') {
    style.backgroundColor = customBackgroundColor || '#00587c'
  }

  if (backgroundImage) {
    style.backgroundImage = `url(${backgroundImage.url}?q=80&w=1400)`
  }

  return (
    <div className={topStyle} style={style} data-qa="title-module-container">
      <Container>
        {richTagline && (
          <RichText
            overrides={{
              paragraph: (node, children) =>
                children && <Eyebrow className={taglineClasses}>{children}</Eyebrow>,
            }}
          >
            {richTagline}
          </RichText>
        )}
        <RichText
          overrides={{
            paragraph: (node, children) =>
              !isEmpty(children) && (
                <Heading
                  element="h1"
                  size={2}
                  className={headingClasses}
                  data-qa="title-module-headline"
                >
                  {children}
                </Heading>
              ),
          }}
        >
          {richHeadline}
        </RichText>

        {richSubHeadline && (
          <RichText
            overrides={{
              paragraph: (node, children) =>
                !isEmpty(children) && (
                  <Heading element="p" size={4} className={bodyClasses} data-qa="title-module-body">
                    {children}
                  </Heading>
                ),
            }}
          >
            {richSubHeadline}
          </RichText>
        )}

        {buttonCta && buttonUrl && (
          <div className={styles.ctaContainer} data-qa="title-module-cta-container">
            <Button url={buttonUrl} variant="light" alt={buttonAlt}>
              {buttonCta}
            </Button>

            {linkTextCta && linkTextUrl && (
              <a href={linkTextUrl} className={linkTextClasses} aria-label={linkTextAlt}>
                {linkTextCta}
              </a>
            )}
          </div>
        )}
      </Container>
    </div>
  )
}

TitleModule.propTypes = {
  richHeadline: PropTypes.object.isRequired,
  richSubHeadline: PropTypes.object,
  richTagline: PropTypes.object,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  buttonUrl: PropTypes.string,
  buttonAlt: PropTypes.string,
  buttonCta: PropTypes.string,
  linkTextCta: PropTypes.string,
  linkTextUrl: PropTypes.string,
  linkTextAlt: PropTypes.string,
  fontColor: PropTypes.string.isRequired,
  customBackgroundColor: PropTypes.string,
}

export { TitleModule }
export default TitleModule
