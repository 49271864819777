/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../grid'
import { RichText } from '../../rich-text'
import * as styles from './CopyBlockModule.module.scss'

const CopyBlockModule = ({ richBody, listType }) => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className={styles.contentContainer}>
            <div className={styles.copy} data-qa="copy-block-module-container">
              <RichText listType={listType}>{richBody}</RichText>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

CopyBlockModule.propTypes = {
  richBody: PropTypes.object.isRequired,
  listType: PropTypes.string,
}

export { CopyBlockModule }
export default CopyBlockModule
