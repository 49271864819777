import PropTypes from 'prop-types'

import { Image } from '../../../image/Image'
import { UnstyledLink } from '../../../typography/UnstyledLink'

import * as styles from './HomepageHeroCard.module.scss'

const imgSrcSets = [
  {
    width: 96,
  },
  {
    media: '(max-width: 600px)',
    width: 84,
  },
]

const Card = ({ cardId, cardUrl, headline, image, cardOnly }) => {
  const {
    file: { url, contentType },
  } = image

  return (
    <UnstyledLink
      className={cardOnly ? styles.HomepageHeroCardOnly : styles.HomepageHeroCard}
      to={cardUrl}
      data-card={cardId}
      data-qa="homepage-hero-card"
    >
      <div className={styles.imageContainer}>
        <Image
          className={cardOnly ? styles.imageCardOnly : styles.image}
          type={contentType}
          src={url}
          alt=""
          data-qa="homepage-hero-card-image"
          srcSets={imgSrcSets}
        />
      </div>
      <p className={cardOnly ? styles.copyCardOnly : styles.copy} data-qa="homepage-hero-card-copy">
        {headline}
      </p>
    </UnstyledLink>
  )
}

Card.propTypes = {
  cardId: PropTypes.number.isRequired,
  cardUrl: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  cardOnly: PropTypes.bool,
}

export { Card }
export default Card
