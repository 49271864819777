import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { Heading } from '../../typography/Heading'
import { Eyebrow } from '../../typography/Eyebrow'
import { Container, Row, Col } from '../../grid'
import { Button } from '../../button'
import { RichText } from '../../rich-text'
import * as styles from './VideoCopyModule.module.scss'

const ButtonElement = ({
  buttonCta,
  buttonUrl,
  buttonColor,
  alt,
  buttonTarget = '_self',
  ...props
}) => {
  if (buttonColor === 'light') {
    return (
      <Button url={buttonUrl} variant="secondary" alt={alt} target={buttonTarget} {...props}>
        {buttonCta}
      </Button>
    )
  }

  return (
    <Button url={buttonUrl} target={buttonTarget} alt={alt} {...props}>
      {buttonCta}
    </Button>
  )
}

ButtonElement.propTypes = {
  buttonColor: PropTypes.string,
  buttonCta: PropTypes.string,
  buttonTarget: PropTypes.string,
  buttonUrl: PropTypes.string,
  alt: PropTypes.string,
}

const VideoCopyModule = ({
  richTagline,
  richHeadline,
  video,
  richBody,
  videoPosition,
  buttonCtaOne,
  buttonUrlOne,
  buttonOneColor,
  buttonTargetOne,
  buttonAltOne,
  buttonCtaTwo,
  buttonUrlTwo,
  buttonTwoColor,
  buttonTargetTwo,
  buttonAltTwo,
  disclaimer,
}) => {
  const innerContentRight = classNames(styles.contentContainerInner)
  const innerContentLeft = classNames(styles.contentContainerInner)

  if (videoPosition === 'top') {
    return (
      <Container>
        <Row className={styles.videoCopyModuleTop}>
          <Col xs={12}>
            <div data-qa="video-copy-module-top-container">
              <div className={styles.innerIFrameContainer}>
                <iframe
                  title="video-player"
                  className={styles.iFrame}
                  src={`${video}?rel=0&`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className={styles.partialBackground} aria-hidden="true" />
            </div>
          </Col>

          <Col xs={12}>
            <div className={styles.contentContainer}>
              <div className={styles.contentContainerInner}>
                {richTagline && (
                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        children && (
                          <Eyebrow element="h2" className={styles.tagline}>
                            {children}
                          </Eyebrow>
                        ),
                    }}
                  >
                    {richTagline}
                  </RichText>
                )}
                <RichText
                  overrides={{
                    paragraph: (node, children) =>
                      !isEmpty(children) && (
                        <Heading element="h3" size={3} className={styles.heading}>
                          {children}
                        </Heading>
                      ),
                  }}
                >
                  {richHeadline}
                </RichText>

                <div className={styles.copy} data-qa="video-copy-module-copy">
                  <RichText>{richBody}</RichText>
                </div>

                {buttonCtaOne && buttonUrlOne && buttonOneColor && (
                  <div className={styles.buttonRow} data-qa="video-copy-module-buttons">
                    <ButtonElement
                      buttonColor={buttonOneColor}
                      buttonUrl={buttonUrlOne}
                      buttonCta={buttonCtaOne}
                      buttonTarget={buttonTargetOne}
                      alt={buttonAltOne}
                    />

                    {buttonCtaTwo && buttonUrlTwo && buttonTwoColor && (
                      <ButtonElement
                        buttonColor={buttonTwoColor}
                        buttonUrl={buttonUrlTwo}
                        buttonCta={buttonCtaTwo}
                        buttonTarget={buttonTargetTwo}
                        alt={buttonAltTwo}
                      />
                    )}
                  </div>
                )}

                {disclaimer && (
                  <div className={styles.disclaimer} data-qa="video-copy-module-disclaimer">
                    <RichText>{disclaimer}</RichText>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (videoPosition === 'right') {
    return (
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <div className={styles.contentContainer}>
              <div className={innerContentLeft}>
                {richTagline && (
                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        children && (
                          <Eyebrow element="h2" className={styles.tagline}>
                            {children}
                          </Eyebrow>
                        ),
                    }}
                  >
                    {richTagline}
                  </RichText>
                )}
                <RichText
                  overrides={{
                    paragraph: (node, children) =>
                      !isEmpty(children) && (
                        <Heading element="h3" size={3} className={styles.heading}>
                          {children}
                        </Heading>
                      ),
                  }}
                >
                  {richHeadline}
                </RichText>

                <div className={styles.copy} data-qa="video-copy-module-copy">
                  <RichText>{richBody}</RichText>
                </div>

                {buttonCtaOne && buttonUrlOne && buttonOneColor && (
                  <div className={styles.buttonRow} data-qa="video-copy-module-buttons">
                    <ButtonElement
                      buttonColor={buttonOneColor}
                      buttonUrl={buttonUrlOne}
                      buttonCta={buttonCtaOne}
                      buttonTarget={buttonTargetOne}
                      alt={buttonAltOne}
                    />

                    {buttonCtaTwo && buttonUrlTwo && buttonTwoColor && (
                      <ButtonElement
                        buttonColor={buttonTwoColor}
                        buttonUrl={buttonUrlTwo}
                        buttonCta={buttonCtaTwo}
                        buttonTarget={buttonTargetTwo}
                        alt={buttonAltTwo}
                      />
                    )}
                  </div>
                )}

                {disclaimer && (
                  <div className={styles.disclaimer} data-qa="video-copy-module-disclaimer">
                    <RichText>{disclaimer}</RichText>
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col xs={12} lg={6} className={styles.iFrameContainerRightColumn}>
            <div className={styles.iFrameContainerRight} data-qa="video-copy-module-left-container">
              <div className={styles.innerIFrameContainer}>
                <iframe
                  title="video-player"
                  className={styles.iFrame}
                  src={`${video}?rel=0&`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className={styles.partialBackground} aria-hidden="true" />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Col xs={12} lg={6}>
          <div className={styles.iFrameContainerLeft} data-qa="video-copy-module-left-container">
            <div className={styles.innerIFrameContainer}>
              <iframe
                title="video-player"
                className={styles.iFrame}
                src={`${video}?rel=0&`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className={styles.partialBackground} aria-hidden="true" />
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <div className={styles.contentContainer}>
            <div className={innerContentRight}>
              {richTagline && (
                <RichText
                  overrides={{
                    paragraph: (node, children) =>
                      children && (
                        <Eyebrow element="h2" className={styles.tagline}>
                          {children}
                        </Eyebrow>
                      ),
                  }}
                >
                  {richTagline}
                </RichText>
              )}
              <RichText
                overrides={{
                  paragraph: (node, children) =>
                    !isEmpty(children) && (
                      <Heading element="h3" size={3} className={styles.heading}>
                        {children}
                      </Heading>
                    ),
                }}
              >
                {richHeadline}
              </RichText>

              <div className={styles.copy} data-qa="video-copy-module-copy">
                <RichText>{richBody}</RichText>
              </div>

              {buttonCtaOne && buttonUrlOne && buttonOneColor && (
                <div className={styles.buttonRow} data-qa="video-copy-module-buttons">
                  <ButtonElement
                    buttonColor={buttonOneColor}
                    buttonUrl={buttonUrlOne}
                    buttonCta={buttonCtaOne}
                    buttonTarget={buttonTargetOne}
                    alt={buttonAltOne}
                  />

                  {buttonCtaTwo && buttonUrlTwo && buttonTwoColor && (
                    <ButtonElement
                      buttonColor={buttonTwoColor}
                      buttonUrl={buttonUrlTwo}
                      buttonCta={buttonCtaTwo}
                      buttonTarget={buttonTargetTwo}
                      alt={buttonAltTwo}
                    />
                  )}
                </div>
              )}

              {disclaimer && (
                <div className={styles.disclaimer} data-qa="video-copy-module-disclaimer">
                  <RichText>{disclaimer}</RichText>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

VideoCopyModule.propTypes = {
  richTagline: PropTypes.object,
  richHeadline: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired,
  richBody: PropTypes.object.isRequired,
  videoPosition: PropTypes.string,
  buttonCtaOne: PropTypes.string,
  buttonUrlOne: PropTypes.string,
  buttonOneColor: PropTypes.string,
  buttonTargetOne: PropTypes.string,
  buttonAltOne: PropTypes.string,
  buttonCtaTwo: PropTypes.string,
  buttonUrlTwo: PropTypes.string,
  buttonTwoColor: PropTypes.string,
  buttonTargetTwo: PropTypes.string,
  buttonAltTwo: PropTypes.string,
  videoBackgroundColor: PropTypes.string,
  disclaimer: PropTypes.object,
}

export { VideoCopyModule }
export default VideoCopyModule
