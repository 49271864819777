// import { Fragment } from 'react'
import { Row, Col } from 'components/grid'
import PropTypes from 'prop-types'
import { camelCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ImageCopyModule } from '../ImageCopy'
import { StackedHeroWithImage } from '../StackedHeroWithImage'
import { convert24HourTo12Hour } from './utils'

// import { SubNav } from '../../header/SubNav'

const LocationSection = ({
  menu,
  name,
  pageUrl,
  scheduleAppointmentUrl,
  address,
  city,
  state,
  zip,
  type,
  offerings,
  officeType,
  imageOne,
  imageTwo,
  richBody,
  richTagline,
  richHeadline,
  richTagline2,
  richHeadline2,
  getDirectionsUrl,
  ...props
}) => {
  const [t] = useTranslation()

  // copied from Location Details, possibility to extract to common util file
  const DAYS_OF_WEEK = {
    monday: t('DaysOfWeek.monday', { defaultValue: 'Monday' }),
    tuesday: t('DaysOfWeek.tuesday', { defaultValue: 'Tuesday' }),
    wednesday: t('DaysOfWeek.wednesday', { defaultValue: 'Wednesday' }),
    thursday: t('DaysOfWeek.thursday', { defaultValue: 'Thursday' }),
    friday: t('DaysOfWeek.friday', { defaultValue: 'Friday' }),
    saturday: t('DaysOfWeek.saturday', { defaultValue: 'Saturday' }),
    sunday: t('DaysOfWeek.sunday', { defaultValue: 'Sunday' }),
  }

  const richBodyBottom = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'heading-3',
        marks: [],
        data: {},
        content: [
          {
            value: `${officeType} ${t(`LocationDetails.hours`, { defaultValue: 'Hours' })}`,
            nodeType: 'text',
            marks: [],
            data: {},
          },
        ],
      },
      {
        nodeType: 'text',
        marks: [],
        value: '\n',
        data: {},
      },
    ],
  }

  const tableData = []

  Object.keys(DAYS_OF_WEEK).forEach(dayOfWeek => {
    const day = DAYS_OF_WEEK[dayOfWeek]
    const openTime = props[`${dayOfWeek}Open`]
    const closeTime = props[`${dayOfWeek}Close`]
    if (openTime && closeTime) {
      const convertedHours = `${convert24HourTo12Hour(openTime)} - ${convert24HourTo12Hour(
        closeTime
      )}`
      const tmpObj = { day, convertedHours }
      tableData.push(tmpObj)
    }
  })

  const imageCopyRichBody = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'unordered-list',
        data: {},
        content: [],
      },
    ],
  }

  offerings.sort().forEach(item => {
    imageCopyRichBody.content[0].content.push({
      data: {},
      nodeType: 'list-item',
      content: [
        {
          nodeType: 'paragraph',
          marks: [],
          data: {},
          content: [
            {
              nodeType: 'text',
              marks: [],
              value: `${t(`LocationDetails.${camelCase(item)}`, { defaultValue: item })}`,
              data: {},
            },
          ],
        },
      ],
    })
  })

  const richAddress = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'hyperlink',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: `${address}`,
            data: {},
          },
          {
            nodeType: 'text',
            marks: [],
            value: '\n',
            data: {},
          },
          {
            nodeType: 'text',
            marks: [],
            value: `${city}, ${state}`,
            data: {},
          },
        ],
        data: {
          uri: getDirectionsUrl,
        },
      },
    ],
  }

  const menuWithLocation = { ...menu }

  menuWithLocation.subnav.push({
    title: t(`HamburgerMenu.locationsLinkLabel`, { defaultValue: 'Locations' }),
    urlPath: '/branch-locations',
    children: [
      {
        title: name,
        urlPath: `${pageUrl}`,
      },
    ],
  })

  return (
    <div>
      <Row>
        <Col>
          <StackedHeroWithImage
            richTagline={richTagline}
            richHeadline={richHeadline}
            backgroundColor="personal"
            buttonCta={t('LocationDetails.scheduleAppointment', {
              defaultValue: 'Schedule an Appointment',
            })}
            buttonUrl={scheduleAppointmentUrl}
            buttonTarget="_blank"
            buttonAlt={t('LocationDetails.scheduleAppointment', {
              defaultValue: 'Schedule an Appointment',
            })}
            linkCta={t('LocationCard.getDirectionsLinkLabel', {
              defaultValue: 'Get Directions',
            })}
            linkUrl={getDirectionsUrl}
            linkTarget="_blank"
            image={imageOne}
            richBody={richBody}
            richBodyBottom={richBodyBottom}
            address={richAddress}
            richBottomCol1={tableData}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageCopyModule
            richTagline={richTagline2}
            richHeadline={richHeadline2}
            richBody={imageCopyRichBody}
            image={imageTwo}
            imagePosition="left"
            buttonCtaOne={t('LocationDetails.scheduleAppointment', {
              defaultValue: 'Schedule an Appointment',
            })}
            buttonUrlOne={scheduleAppointmentUrl}
            buttonColorOne="dark"
            buttonTargetOne="_self"
            buttonAltOne={t('LocationDetails.scheduleAppointment', {
              defaultValue: 'Schedule an Appointment',
            })}
          />
        </Col>
      </Row>
    </div>
  )
}

LocationSection.propTypes = {
  menu: PropTypes.object,
  name: PropTypes.string,
  pageUrl: PropTypes.string,
  scheduleAppointmentUrl: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  type: PropTypes.array,
  offerings: PropTypes.array,
  imageOne: PropTypes.object,
  imageTwo: PropTypes.object,
  richBody: PropTypes.object,
  officeType: PropTypes.string,
  richTagline: PropTypes.object,
  richHeadline: PropTypes.object,
  richTagline2: PropTypes.object,
  richHeadline2: PropTypes.object,
  getDirectionsUrl: PropTypes.string,
}

export { LocationSection }
export default LocationSection
