import { Trans, useTranslation } from 'react-i18next'
import * as styles from './InvestmentLogin.module.scss'
import { Link } from '../../typography/Link'
import { Heading } from '../../typography/Heading'
import { Caption } from '../../typography/Caption'
import { TextField } from '../../textfield'
import { Button } from '../../button'

const InvestmentLogin = () => {
  const [t] = useTranslation()
  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginContainer}>
        <Heading element="h4" size={4}>
          {t('InvestmentLogin.headline', { defaultValue: 'Welcome to Member Portfolio View' })}
        </Heading>
        <p>
          <Trans i18nKey="InvestmentLogin.message">
            <strong>
              Please use your Member ID (not to be confused with your credit union member
              number)&nbsp;
            </strong>
            and password to view your brokerage† account online through CUSO Financial Services, LP
            (“CFS”).
          </Trans>
          <br aria-hidden="true" />
          <br aria-hidden="true" />
        </p>
        <form
          acceptCharset="UTF-8"
          action="https://mpv3.orcasnet.com/#/login/UC"
          method="post"
          name="auth_form"
          target="_top"
        >
          <div className={styles.innerFormContainer}>
            <input name="utf8" type="hidden" value="✓" />
            <input
              name="authenticity_token"
              type="hidden"
              value="QniD9MLdgYK7bIHzx9lfVe0ZaiJ4PGRXaF20cxgs4Gs="
            />
          </div>
          <div className={styles.memberIdContainer}>
            <TextField
              label={t('', { defaultValue: 'Member ID:' })}
              id="login"
              name="login"
              type="text"
              autoFocus
            />
          </div>
          <input id="workgroup_code" name="workgroup_code" type="hidden" value="uc" />
          <br aria-hidden="true" />
          <div className={styles.needHelpContainer}>
            <Button variant="primary" type="submit" target="_top">
              {t('InvestmentLogin.loginButtonLabel', { defaultValue: 'Log In' })}
            </Button>
            <Link to="https://ola.orcasnet.com/cuso/members/mpv_directory.html">
              {t('InvestmentLogin.needHelpLinkLabel', { defaultValue: 'Need help?' })}
            </Link>
          </div>
        </form>
      </div>
      <div>
        <div className={styles.contentContainer}>
          <p>
            <Trans i18nKey="InvestmentLogin.haveQuestions">
              Have questions? We’re here to help.&nbsp;
              <Link to="/financial-planning/advisor-team">
                Please contact one of our CFS* Wealth Management Advisors.
              </Link>
              &nbsp;You may also ask to have your Member ID and password mailed to your address on
              record. We recommend that you receive this confidential information in person or by
              mail, rather than through unsecured email.
            </Trans>
          </p>
          <br aria-hidden="true" />
          <Caption>
            <Trans i18nKey="InvestmentLogin.checkInformation">
              Check information on this firm with&nbsp;
              <Link to="https://brokercheck.finra.org">FINRA’s Broker Check.</Link>
              <br aria-hidden="true" />
              †If your assets are held directly at a mutual fund or annuity company, you may not be
              able to view your account information online. Currently, not all mutual fund or
              annuity companies are participating in our online account service. New participants
              will be added periodically, so check with your representative for updates.
            </Trans>
          </Caption>
          <br aria-hidden="true" />
          <Caption>
            <Trans i18nKey="InvestmentLogin.nonDeposit">
              <sup>*</sup>Non-deposit investment products and services are offered through CUSO
              Financial Services, L.P. ("CFS"), a registered broker-dealer (Member FINRA/SIPC) and
              SEC Registered Investment Advisor. Products offered through CFS:&nbsp;
              <strong>
                are not NCUA/NCUSIF or otherwise federally insured, are not guarantees or
                obligations of the credit union, and may involve investment risk including possible
                loss of principal.&nbsp;
              </strong>
              Investment Representatives are registered through CFS. Elevations Credit Union has
              contracted with CFS to make non-deposit investment products and services available to
              credit union members.
              <br aria-hidden="true" />
              <br aria-hidden="true" />
              Financial Advisors are registered to conduct securities business and licensed to
              conduct insurance business in limited states. Response to, or contact with, residents
              of other states will be made only upon compliance with applicable licensing and
              registration requirements. The information in this website is for U.S. residents only
              and does not constitute an offer to sell, or a solicitation of an offer to purchase
              brokerage services to persons outside of the United States.
            </Trans>
          </Caption>
        </div>
      </div>
    </div>
  )
}

export { InvestmentLogin }
export default InvestmentLogin
