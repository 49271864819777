import { Fragment, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { RichText } from '../../rich-text'
import { Eyebrow } from '../../typography/Eyebrow'
import { Heading } from '../../typography/Heading'
import { Container, Row, Col } from '../../grid'
import { Button } from '../../button'
import { Link } from '../../typography/Link'
import { Image } from '../../image'

import * as styles from './StackedHeroWithImage.module.scss'

const StackedHeroWithImage = ({
  richTagline,
  richHeadline,
  richBody,
  image,
  buttonCta,
  buttonUrlLong,
  buttonTarget = '_self',
  buttonAlt,
  buttonIsModal,
  linkCta,
  linkUrlLong,
  linkTarget = '_self',
  linkAlt,
  backgroundColor,
  customBackgroundColor,
  richBodyBottom,
  disclaimer,
  bottomDisclaimer,
  address,
  richBottomCol1,
  richBottomCol2,
  modalUrlIsMember,
  modalUrlNonMember,
  isApply,
  applyHref,
}) => {
  const targetRef = useRef()
  const [height, setHeight] = useState(0)

  const topStyle =
    backgroundColor === 'business'
      ? classNames(styles.StackedHeroWithImageTopWrapper, styles.Business)
      : styles.StackedHeroWithImageTopWrapper

  const customStyle = {}

  if (backgroundColor === 'other') {
    customStyle.backgroundColor = customBackgroundColor || '#00587c'
  }

  const imgSrcSets = [
    {
      media: '(max-width: 960px)',
      width: 500,
    },
    {
      width: 700,
    },
  ]
  if (typeof document !== 'undefined') {
    const reservedP = document.getElementsByTagName('p')
    let textP
    let newTextP
    for (let i = 0; i < reservedP.length; i += 1) {
      textP = reservedP[i].innerHTML
      if (!textP.includes('</span>')) {
        newTextP = textP.replace(
          /®/gi,
          "<span style='font-size: .4em; vertical-align: super'>®</span>"
        )
        document.getElementsByTagName('p')[i].innerHTML = newTextP
      }
    }
  }
  useEffect(() => {
    // Always set the height on load
    setHeight(targetRef.current.offsetHeight)
    // Listen for resizes
    const handleResize = () => setHeight(targetRef.current.offsetHeight)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <div className={styles.StackedHeroWithImage} ref={targetRef} data-qa="stacked-hero">
      <div className={topStyle} data-qa="stacked-hero-top-wrapper" style={customStyle}>
        <Container>
          <Row className={styles.StackedHeroWithImageTop}>
            <Col xs={12} md={6}>
              {richTagline && (
                <Fragment>
                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        !isEmpty(children) && (
                          <Eyebrow element="h1" data-qa="stacked-hero-tagline">
                            {children}
                          </Eyebrow>
                        ),
                    }}
                  >
                    {richTagline}
                  </RichText>

                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        !isEmpty(children) && (
                          <h2 className={styles.heading} data-qa="stacked-hero-headline">
                            {children}
                          </h2>
                        ),
                    }}
                  >
                    {richHeadline}
                  </RichText>
                </Fragment>
              )}
              {!richTagline && (
                <RichText
                  overrides={{
                    paragraph: (node, children) =>
                      !isEmpty(children) && (
                        <Heading
                          element="h1"
                          size={2}
                          className={styles.heading}
                          data-qa="stacked-hero-headline"
                        >
                          {children}
                        </Heading>
                      ),
                  }}
                >
                  {richHeadline}
                </RichText>
              )}

              {address && (
                <div className={styles.addressContainer} data-qa="stacked-hero-address">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    className={styles.svgIcon}
                  >
                    {/* <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
                    <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                  </svg>
                  <RichText theme="light">{address}</RichText>
                </div>
              )}

              <div className={styles.copy} data-qa="stacked-hero-top-copy">
                <RichText theme="light">{richBody}</RichText>
              </div>

              {buttonCta && buttonUrlLong && !buttonIsModal && !isApply && (
                <Button
                  className={styles.linkOneCta}
                  url={buttonUrlLong.buttonUrlLong}
                  target={buttonTarget}
                  variant="light"
                  alt={buttonAlt}
                  isModal={buttonIsModal}
                >
                  {buttonCta}
                </Button>
              )}
              {buttonCta && buttonIsModal && (
                <Button
                  className={styles.linkOneCta}
                  target={buttonTarget}
                  variant="light"
                  alt={buttonAlt}
                  isModal={buttonIsModal}
                  modalUrlIsMember={modalUrlIsMember}
                  modalUrlNonMember={modalUrlNonMember}
                >
                  {buttonCta}
                </Button>
              )}
              {applyHref && isApply && (
                <Button
                  className={styles.linkOneCta}
                  target={buttonTarget}
                  variant="light"
                  alt={buttonAlt}
                  isApply={isApply}
                  applyHref={applyHref}
                >
                  {buttonCta}
                </Button>
              )}

              {linkCta && linkUrlLong && (
                <Link
                  className={styles.linkTwoCta}
                  to={linkUrlLong.linkUrlLong}
                  target={linkTarget}
                  alt={linkAlt}
                  light
                >
                  {linkCta}
                </Link>
              )}

              {disclaimer && (
                <div className={styles.disclaimer} data-qa="stacked-hero-top-disclaimer">
                  <RichText theme="light">{disclaimer}</RichText>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <div data-qa="stacked-hero-bottom-wrapper">
        <Container>
          <Row className={styles.StackedHeroWithImageBottom}>
            <Col xs={12} md={6}>
              <div className={styles.bottomCopy} data-qa="stacked-hero-bottom-copy">
                <RichText listType="checklist">{richBodyBottom}</RichText>
              </div>

              {richBottomCol1 && (
                <Row>
                  <table role="presentation">
                    <tbody>
                      {richBottomCol1.map(day => {
                        return (
                          <tr>
                            <td className={styles.tableDay}>{day.day}</td>
                            <td>{day.convertedHours}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Row>
              )}

              {bottomDisclaimer && (
                <div className={styles.disclaimer} data-qa="stacked-hero-bottom-disclaimer">
                  <RichText>{bottomDisclaimer}</RichText>
                </div>
              )}
            </Col>

            <Col className={styles.imageCol} xs={12} md={6}>
              <div className={styles.imageWrapper}>
                <Image
                  type={image.contentType}
                  src={image.url}
                  alt={image.description}
                  style={{ maxHeight: height - 100 }}
                  data-qa="stacked-hero-bottom-image"
                  srcSets={imgSrcSets}
                />
                {image.caption && (
                  <div className={styles.caption} data-qa="image-copy-module-disclaimer">
                    <RichText>{image.caption}</RichText>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

StackedHeroWithImage.propTypes = {
  richTagline: PropTypes.object,
  richHeadline: PropTypes.object.isRequired,
  richBody: PropTypes.object.isRequired,
  buttonCta: PropTypes.string.isRequired,
  buttonUrlLong: PropTypes.object,
  buttonTarget: PropTypes.string,
  buttonAlt: PropTypes.string,
  buttonIsModal: PropTypes.bool,
  image: PropTypes.object.isRequired,
  linkCta: PropTypes.string,
  linkUrlLong: PropTypes.object,
  linkTarget: PropTypes.string,
  linkAlt: PropTypes.string,
  backgroundColor: PropTypes.string,
  customBackgroundColor: PropTypes.string,
  richBodyBottom: PropTypes.object,
  disclaimer: PropTypes.object,
  bottomDisclaimer: PropTypes.object,
  address: PropTypes.object,
  richBottomCol1: PropTypes.array,
  modalUrlIsMember: PropTypes.string,
  modalUrlNonMember: PropTypes.string,
  isApply: PropTypes.bool,
  applyHref: PropTypes.string,
}

export { StackedHeroWithImage }
export default StackedHeroWithImage
