// extracted by mini-css-extract-plugin
export var TitleModule = "TitleModule-module--TitleModule--2W-dC";
export var Business = "TitleModule-module--Business--3zPhd";
export var Personal = "TitleModule-module--Personal--2Ahw3";
export var heading = "TitleModule-module--heading--2fCay";
export var body = "TitleModule-module--body--CTZcs";
export var tagline = "TitleModule-module--tagline--Q8PE9";
export var linkText = "TitleModule-module--linkText--wUXR7";
export var ctaContainer = "TitleModule-module--ctaContainer--2w0aS";
export var white = "TitleModule-module--white--EhIio";
export var lightBlue = "TitleModule-module--lightBlue--39PvV";
export var iceBlue = "TitleModule-module--iceBlue--1TvOk";
export var black = "TitleModule-module--black--2DNMb";