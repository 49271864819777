// extracted by mini-css-extract-plugin
export var headline = "HeaderBioModule-module--headline--ewInR";
export var centered = "HeaderBioModule-module--centered--2hrF7";
export var buttonDiv = "HeaderBioModule-module--buttonDiv--zDmwz";
export var HeaderBioModule = "HeaderBioModule-module--HeaderBioModule--lMHXk";
export var HeaderBioModuleInnerContainer = "HeaderBioModule-module--HeaderBioModuleInnerContainer--21Kva";
export var Oval = "HeaderBioModule-module--Oval--27Ua5";
export var linkImageContainer = "HeaderBioModule-module--linkImageContainer--adJUd";
export var LongerfirstnameLong = "HeaderBioModule-module--LongerfirstnameLong--29X_-";
export var LengthyTitle = "HeaderBioModule-module--LengthyTitle--2CkuD";
export var LocationOffice = "HeaderBioModule-module--LocationOffice--2wl1h";
export var textStyle1 = "HeaderBioModule-module--text-style-1--2r8Pq";
export var aboutBody = "HeaderBioModule-module--aboutBody--1UZzn";
export var CtaButton = "HeaderBioModule-module--CtaButton--1cF5V";
export var headerBioAbout = "HeaderBioModule-module--headerBioAbout--1oNlu";
export var aboutHeader = "HeaderBioModule-module--aboutHeader--iltBy";
export var ctaContainer = "HeaderBioModule-module--ctaContainer--2LX4K";
export var linkContainer = "HeaderBioModule-module--linkContainer--yDjeC";
export var headerBioFirstLastName = "HeaderBioModule-module--headerBioFirstLastName--1R979";
export var Cta2Button = "HeaderBioModule-module--Cta2Button--1M1gu";
export var ImageContainer = "HeaderBioModule-module--ImageContainer--27jv8";
export var readMoreButton = "HeaderBioModule-module--readMoreButton--5jW2u";
export var titleContainer = "HeaderBioModule-module--titleContainer--3t8Kn";
export var mortgageTeamLink = "HeaderBioModule-module--mortgageTeamLink--12nWa";
export var phoneIcon = "HeaderBioModule-module--phoneIcon--2oOwZ";
export var learnMoreLink = "HeaderBioModule-module--learnMoreLink--3Ov9n";
export var backToLink = "HeaderBioModule-module--backToLink--2BE_G";
export var locationContainer = "HeaderBioModule-module--locationContainer--1XrLi";
export var separatorContainer = "HeaderBioModule-module--separatorContainer--21hjX";
export var nameContainer = "HeaderBioModule-module--nameContainer--2ahjC";
export var title = "HeaderBioModule-module--title--1WF0l";
export var phoneNumber = "HeaderBioModule-module--phoneNumber--1mjvS";
export var email = "HeaderBioModule-module--email--3f-QO";
export var NMLS = "HeaderBioModule-module--NMLS--3kjvK";
export var phoneNumberContainer = "HeaderBioModule-module--phoneNumberContainer--2B7HI";
export var nmlsContainer = "HeaderBioModule-module--nmlsContainer--2oLGZ";