/* eslint-disable react/no-danger */
import Iframe from 'react-iframe'
import { Container, Row, Col } from '../../grid'
import * as styles from './DonationFormModule.module.scss'

const DonationFormModule = () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div>
            <Iframe
              url="https://app.etapestry.com/onlineforms/ElevationsFoundationInc_2/gendonations.html"
              width="100%"
              height="2000px"
              className={styles.iframe}
              display="initial"
              position="relative"
              title="Elevations foundation donation form"
              data-qa="donation-module-container"
            />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export { DonationFormModule }
export default DonationFormModule
