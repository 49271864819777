// this function is copied from LocationDetails Page, opportunity to reduce code by extracting to a utils file
export const convert24HourTo12Hour = time => {
  const timeElements = time.match(/(\d+):(\d+)/)
  const hour = timeElements[1]
  const minute = timeElements[2]

  let hourBase12 = hour % 12
  if (hourBase12 === 0) hourBase12 = 12
  const suffix = hour >= 12 ? 'pm' : 'am'

  return `${hourBase12}:${minute} ${suffix}`
}
