import PropTypes from 'prop-types'

import { LocationSection } from 'components/modules/LocationSection'
import { UpcomingEventModule } from '../components/modules/UpcomingEventModule'
import { ImageCopyModule } from '../components/modules/ImageCopy'
import { ImageCopy25Module } from '../components/modules/ImageCopy25'
import { TitleModule } from '../components/modules/TitleModule'
import { StackedHeroWithImage } from '../components/modules/StackedHeroWithImage'
import { AccordionFaq } from '../components/modules/AccordionFaq'
import { CardModule } from '../components/modules/CardModule'
import { ProductTile } from '../components/modules/ProductTile'
import { ElevatedTitleCentered } from '../components/modules/ElevatedTitleCentered'
import { CopyBlockModule } from '../components/modules/CopyBlock'
import { TeamMemberCardModule } from '../components/modules/TeamMemberModule'
import { DisclaimerModule } from '../components/modules/Disclaimer'
import { TableModule } from '../components/modules/Table'
import { HomepageHeroModule } from '../components/modules/HomepageHero'
import { EventsModule } from '../components/modules/Events'
import { VideoCopyModule } from '../components/modules/VideoCopy'
import { HeaderBioModule } from '../components/modules/HeaderBio'
import { AdapterModule } from '../components/modules/AdapterModule'
import { CodeEmbedModule } from '../components/modules/CodeEmbed'
/**
 * This function maps contentful moduleType strings to a React component (module) + appropriate
 * configuration of that module to creat the appropriate visual module.
 * @param {object} item The object model content from Contentful to drive module creation.
 * @param {string} item.moduleType The string containing the module type classifier to determine
 * which module to return.
 */
const GetSectionItem = ({ item, index, menu }) => {
  switch (item.moduleType) {
    case 'faqs':
      return <AccordionFaq {...item} id={String(index)} />
    /* Both of these contentful module names map to the same UI component with the same config */
    case 'card-with-bottom-border':
      return <CardModule {...item} columns={3} headerLevel={index === 0 ? 1 : 2} centered />
    case 'three-column-callout':
      return (
        <CardModule
          {...item}
          columns={3}
          headerLevel={index === 0 ? 1 : 2}
          centered
          blueHeader
          backgroundColor="white"
        />
      )
    case 'two-column-card-repeater':
      return <CardModule {...item} columns={2} headerLevel={index === 0 ? 1 : 2} />
    case 'stacked-hero-with-image':
      return <StackedHeroWithImage {...item} />
    case 'product-comparison':
      return <ProductTile {...item} />
    case 'title':
      return <TitleModule {...item} />
    case 'upcoming-event':
      return <UpcomingEventModule {...item} />
    case 'image-copy':
      return <ImageCopyModule {...item} />
    case 'image-copy-25':
      return <ImageCopy25Module {...item} />
    case 'elevated-title-headline-centered':
      return <ElevatedTitleCentered {...item} />
    case 'copy-block':
      return <CopyBlockModule {...item} />
    case 'team-member-cards-module':
      return <TeamMemberCardModule {...item} />
    case 'disclaimer':
      return <DisclaimerModule {...item} />
    case 'table':
      return <TableModule {...item} />
    case 'homepage-cards':
      return <HomepageHeroModule {...item} />
    case 'events':
      return <EventsModule {...item} />
    case 'video-copy':
      return <VideoCopyModule {...item} />
    case 'header-bio-module':
      return <HeaderBioModule {...item} />
    case 'location':
      return <LocationSection {...item} menu={menu} />
    case 'adapter':
      return <AdapterModule {...item} />
    case 'code-embed':
      return <CodeEmbedModule {...item} />
    default:
      return (
        <div style={{ padding: '4rem 0' }}>
          Warning, required module "<b>{item.moduleType}</b>" not found
        </div>
      )
  }
}

GetSectionItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  menu: PropTypes.object,
}

export { GetSectionItem }
