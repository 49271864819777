/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Heading } from '../../typography/Heading'
import { Link } from '../../typography/Link'
import { Container } from '../../grid'
import HomepageHeroCard from './components/HomepageHeroCard'
import { HomepageHeroBanner } from './components/HomepageHeroBanner'

import * as styles from './HomepageHeroModule.module.scss'

const HomepageHeroModule = ({
  backgroundImage,
  headline,
  cards,
  bannerHeadline,
  bannerCta,
  bannerUrl,
  bannerAlt,
  cardsOnly,
}) => {
  const [t] = useTranslation()
  const backgroundImageStyle = backgroundImage
    ? `url(${backgroundImage.url}?q=80&w=1400)`
    : 'initial'
  if (cardsOnly) {
    return (
      <div
        className={cardsOnly ? styles.HomepageHeroCardsOnly : styles.HomepageHero}
        data-qa="homepage-hero-container"
      >
        <div
          className={
            cardsOnly ? styles.HomepageHeroCardsWrapperCardsOnly : styles.HomepageHeroCardsWrapper
          }
          id="cards-wrapper"
        >
          <div className={styles.HomepageHeroCards} id="cards">
            <div className={styles.HomepageHeroCardsInner} data-qa="homepage-hero-cards">
              {cards.map((card, index) => (
                <HomepageHeroCard
                  key={card.headline}
                  cardId={index}
                  cardUrl={card.cardUrl}
                  headline={card.headline}
                  image={card.image}
                  cardOnly
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      className={styles.HomepageHero}
      style={{ backgroundImage: backgroundImageStyle }}
      data-qa="homepage-hero-container"
    >
      <Container>
        <Heading element="h1" size={1} className={styles.HomepageHeroHeadline}>
          {headline}
        </Heading>
      </Container>

      <div className={styles.HomepageHeroCardsWrapper} id="cards-wrapper">
        <div className={styles.HomepageHeroCards} id="cards">
          <div className={styles.HomepageHeroCardsInner} data-qa="homepage-hero-cards">
            {cards.map((card, index) => (
              <HomepageHeroCard
                key={card.headline}
                cardId={index}
                cardUrl={card.cardUrl}
                headline={card.headline}
                image={card.image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

HomepageHeroModule.propTypes = {
  backgroundImage: PropTypes.object,
  headline: PropTypes.string.isRequired,
  cards: PropTypes.array,
  bannerHeadline: PropTypes.string.isRequired,
  bannerCta: PropTypes.string.isRequired,
  bannerUrl: PropTypes.string.isRequired,
  bannerAlt: PropTypes.string,
}

export { HomepageHeroModule }
export default HomepageHeroModule
