import PropTypes from 'prop-types'
import { Children } from 'react'
import classNames from 'classnames'
import {
  Accordion as ReachAccordion,
  AccordionItem as ReachAccordionItem,
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons'
import * as styles from './Accordion.module.scss'

const Accordion = ({ children, className, ...props }) => {
  return (
    <ReachAccordion
      className={classNames(styles.Accordion, className)}
      data-qa="accordion-container"
      {...props}
    >
      {children}
    </ReachAccordion>
  )
}

Accordion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const AccordionItem = ({ children, className, ...props }) => {
  return (
    <ReachAccordionItem className={classNames(className)} data-qa="accordion-item" {...props}>
      {children}
    </ReachAccordionItem>
  )
}

AccordionItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const AccordionHeader = ({ element: Element = 'h3', children, className, ...props }) => {
  return (
    <Element>
      <ReachAccordionButton
        className={classNames(className)}
        data-qa="accordion-item-button"
        {...props}
      >
        {children}
        <FontAwesomeIcon
          className={styles.Indicator}
          icon={useAccordionItemContext().isExpanded ? faMinus : faPlus}
        />
      </ReachAccordionButton>
    </Element>
  )
}

AccordionHeader.propTypes = {
  element: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
}

const AccordionPanel = ({ children, className, ...props }) => {
  return (
    <ReachAccordionPanel
      className={classNames(className)}
      data-qa="accordion-item-panel"
      {...props}
    >
      {children}
    </ReachAccordionPanel>
  )
}

AccordionPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

Accordion.Item = AccordionItem
Accordion.Panel = AccordionPanel
Accordion.Header = AccordionHeader

export { Accordion }
export default Accordion
