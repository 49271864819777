import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Button } from '../../button'
import { Container, Row, Col } from '../../grid'
import { RichText } from '../../rich-text'
import * as styles from './ProductTile.module.scss'
import { Eyebrow } from '../../typography/Eyebrow'

const ProductTile = ({
  richHeadline,
  richBody,
  image,
  image2,
  buttonCta,
  buttonUrl,
  buttonAlt,
  buttonTarget,
  richTagline,
  callouts,
  disclaimer,
  buttonIsModal,
  modalUrlNonMember,
  modalUrlIsMember,
  button2Url,
  button2Alt,
  button2Target,
  button2Cta,
  button2IsModal,
  modal2UrlIsMember,
  modal2UrlNonMember,
  isApply,
  applyHref,
}) => {
  // const reserved = document.getElementsByTagName('h2')
  // let text
  // let newText
  // for (let i = 0; i < reserved.length; i += 1) {
  //   text = reserved[i].innerHTML
  //   if (!text.includes('</span>')) {
  //     newText = text.replace(/®/gi, "<span style='font-size: .4em; vertical-align: super'>®</span>")
  //     document.getElementsByTagName('h2')[i].innerHTML = newText
  //   }
  // }
  return (
    <Container>
      <div className={styles.ProductTile} data-qa="product-tile">
        <div className={styles.ProductDescription} data-qa="product-tile-description">
          <Container>
            <Row>
              <Col lg={3}>
                <img
                  className={styles.ProductImage}
                  src={image.url}
                  alt={image.description}
                  data-qa="product-tile-image"
                />
                <div className={styles.UpperAction} data-qa="product-tile-upper-action">
                  {buttonUrl && !buttonIsModal ? (
                    <Button
                      url={buttonUrl}
                      alt={buttonAlt}
                      target={buttonTarget}
                      isModal={buttonIsModal}
                      modalUrlIsMember={modalUrlIsMember}
                      modalUrlNonMember={modalUrlNonMember}
                    >
                      {buttonCta}
                    </Button>
                  ) : (
                    <Button
                      // removing this for modal to work
                      // url={buttonUrl}
                      alt={buttonAlt}
                      target={buttonTarget}
                      isModal={buttonIsModal}
                      modalUrlIsMember={modalUrlIsMember}
                      modalUrlNonMember={modalUrlNonMember}
                    >
                      {buttonCta}
                    </Button>
                  )}
                </div>
                {image2 && image2.file.url && (
                  <React.Fragment>
                    <br aria-hidden="true" />
                    <img
                      className={styles.ProductImage}
                      src={image2.file.url}
                      alt={image2.file.description}
                      data-qa="product-tile-image-secondary"
                    />
                    {button2Url && !button2IsModal ? (
                      <Button
                        url={button2Url}
                        alt={button2Alt}
                        target={button2Target}
                        isModal={button2IsModal}
                        modalUrlIsMember={modal2UrlIsMember}
                        modalUrlNonMember={modal2UrlNonMember}
                      >
                        {button2Cta}
                      </Button>
                    ) : (
                      <Button
                        // removing this for modal to work
                        // url={buttonUrl}
                        alt={button2Alt}
                        target={button2Target}
                        isModal={button2IsModal}
                        modalUrlIsMember={modal2UrlIsMember}
                        modalUrlNonMember={modal2UrlNonMember}
                      >
                        {button2Cta}
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </Col>
              <Col lg={7} className={styles.TextArea}>
                <div className={styles.Caption} data-qa="product-tile-caption">
                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        !isEmpty(children) && (
                          <Eyebrow element="h2">
                            <strong>{children}</strong>
                          </Eyebrow>
                        ),
                    }}
                  >
                    {richHeadline}
                  </RichText>
                </div>
                <RichText
                  overrides={{
                    paragraph: (node, children) =>
                      !isEmpty(children) && (
                        <p className={styles.Tagline} data-qa="product-tile-tagline">
                          {children}
                        </p>
                      ),
                  }}
                >
                  {richTagline}
                </RichText>
                <div className={styles.LowerAction} data-qa="product-tile-lower-action">
                  <Button
                    // url={buttonUrl}
                    alt={buttonAlt}
                    target={buttonTarget}
                    isModal={buttonIsModal}
                    modalUrlIsMember={modalUrlIsMember}
                    modalUrlNonMember={modalUrlNonMember}
                  >
                    {buttonCta}
                  </Button>
                </div>
                <div className={styles.BodyText} data-qa="product-tile-body">
                  <RichText>{richBody}</RichText>
                </div>

                {disclaimer && (
                  <div className={styles.DisclaimerText} data-qa="product-tile-disclaimer">
                    <RichText>{disclaimer}</RichText>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.ProductFeatures} data-qa="product-tile-features">
          <Container>
            <Row>
              {callouts.map((feature, index) => (
                <Col
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${feature.detailName}-${index}`}
                  md={4}
                  className={styles.ProductFeature}
                  data-qa="product-tile-feature"
                >
                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        !isEmpty(children) && (
                          <Eyebrow element="h3">
                            <strong>{children}</strong>
                          </Eyebrow>
                        ),
                    }}
                  >
                    {feature.richDetailName}
                  </RichText>

                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        !isEmpty(children) && (
                          <div className={styles.FeatureValue} data-qa="product-tile-feature-value">
                            {children}
                          </div>
                        ),
                    }}
                  >
                    {feature.richValue}
                  </RichText>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </Container>
  )
}

ProductTile.propTypes = {
  image: PropTypes.object.isRequired,
  image2: PropTypes.object,
  buttonCta: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  buttonAlt: PropTypes.string,
  buttonTarget: PropTypes.string,
  button2Cta: PropTypes.string,
  button2Url: PropTypes.string,
  button2Alt: PropTypes.string,
  button2Target: PropTypes.string,
  richTagline: PropTypes.object.isRequired,
  richHeadline: PropTypes.object.isRequired,
  richBody: PropTypes.object.isRequired,
  callouts: PropTypes.arrayOf(PropTypes.object).isRequired,
  disclaimer: PropTypes.object,
  modalUrlIsMember: PropTypes.string,
  modalUrlNonMember: PropTypes.string,
  buttonIsModal: PropTypes.bool,
  isApply: PropTypes.bool,
  applyHref: PropTypes.string,
}

export { ProductTile }
export default ProductTile
