// extracted by mini-css-extract-plugin
export var ProductTile = "ProductTile-module--ProductTile--1fPFx";
export var ProductDescription = "ProductTile-module--ProductDescription--2X3G4";
export var ProductFeatures = "ProductTile-module--ProductFeatures--2Xz4N";
export var ProductImage = "ProductTile-module--ProductImage--3WxJJ";
export var UpperAction = "ProductTile-module--UpperAction--3ECYP";
export var TextArea = "ProductTile-module--TextArea--2j41f";
export var Caption = "ProductTile-module--Caption--1KtXO";
export var BodyText = "ProductTile-module--BodyText--2J_K1";
export var DisclaimerText = "ProductTile-module--DisclaimerText--2MtXx";
export var LowerAction = "ProductTile-module--LowerAction--2dBTR";
export var ProductFeature = "ProductTile-module--ProductFeature--1LuEn";
export var FeatureValue = "ProductTile-module--FeatureValue--14Ovh";
export var Tagline = "ProductTile-module--Tagline--3EYXz";