import { React, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import MarkdownIt from 'markdown-it'
import Prism from 'prismjs'
import { Helmet } from 'react-helmet'
import { RichText } from '../../rich-text'
import { Heading } from '../../typography/Heading'
import { Container, Row, Col } from '../../grid'
import * as styles from './CodeEmbedModule.module.scss'

const CodeEmbedModule = ({ mloCode, richHeadline, buyer }) => {
  const taglineHeadingLevel = 2
  const headlineHeadingLevel = 1
  const [scriptLoading, setScriptLoading] = useState(true)
  useEffect(() => {
    /* I hate that I had to write it this way however the linter was throwing a fit when the below code was written in a logical way */
    /* below is the home bot script written out into a function that can be called to add the homebot widget */
    if (window) {
      ;(function initializeHomebot(h, b) {
        const w = window
        const d = document
        const s = 'script'
        w.__hb_namespace = h
        w[h] =
          w[h] ||
          function () {
            ;(w[h].q = w[h].q || []).push(h, b)
          }
        const y = d.createElement(s)
        const x = d.getElementsByTagName(s)[0]
        y.async = 1
        y.src = b
        x.parentNode.insertBefore(y, x)
      })('Homebot', 'https://embed.homebotapp.com/lgw/v1/widget.js')
    }
    if (scriptLoading) {
      if (window.__hb_namespace && buyer) {
        setScriptLoading(false)
        setTimeout(() => {
          // eslint-disable-next-line
          window['Homebot']('#homebot_homeowner', mloCode, {'mode': 'buyers-mode', 'size':'compact'})
        }, 500)
      } else if (window.__hb_namespace && !buyer) {
        setScriptLoading(false)
        setTimeout(() => {
          // eslint-disable-next-line
          window['Homebot']('#homebot_homeowner', mloCode, {'size':'compact'})
        }, 500)
      } else {
        setScriptLoading(true)
      }
    }
  }, [])

  return (
    <div>
      {richHeadline && (
        <RichText
          overrides={{
            paragraph: (node, children) =>
              !isEmpty(children) && (
                <Heading
                  element="h1"
                  size={3}
                  className={styles.heading}
                  data-qa="elevated-title-module-title"
                >
                  {children}
                </Heading>
              ),
          }}
        >
          {richHeadline}
        </RichText>
      )}
      {!scriptLoading && <div id="homebot_homeowner" />}
    </div>
  )
}

export { CodeEmbedModule }
export default CodeEmbedModule
