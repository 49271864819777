import { useCallback, useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form/dist/index.ie11'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import find from 'lodash/find'
import InputMask from 'react-input-mask'
import formatError from '../../../utilities/formatError'
import { Container, Row, Col } from '../../grid'
import { Button } from '../../button'
import { TextField } from '../../textfield'
import { Select } from '../../select'
import { Heading } from '../../typography/Heading'
import { Alert } from '../../alert'
import * as styles from './LocalChangeEnrollmentForm.module.scss'
import * as formConstants from '../../../utilities/FormConstants'

export const CAP_REGEX = /^\$?[0-9]+(\.[0-9][0-9])?$/

const LocalChangeEnrollmentForm = () => {
  const [t] = useTranslation()

  const capOptions = [
    { id: 1, title: t('LocalChangeEnrollmentForm.capOptions.no', { defaultValue: 'No' }) },
    { id: 2, title: t('LocalChangeEnrollmentForm.capOptions.yes', { defaultValue: 'Yes' }) },
  ]

  const validationSchema = {
    firstName: { required: true, maxLength: 50 },
    lastName: { required: true, maxLength: 50 },
    email: {
      required: true,
      maxLength: 100,
      validate: value =>
        formConstants.EMAIL_REGEX.test(value) ||
        t('LocalChangeEnrollmentForm.validationMessages.emailRequired', {
          defaultValue: 'A valid email address is required',
        }),
    },
    phoneNumber: {
      required: true,
      validate: value =>
        formConstants.PHONE_REGEX.test(value) ||
        t('LocalChangeEnrollmentForm.validationMessages.phoneNumberValid', {
          defaultValue:
            'Please enter your full phone number, including area code in the following format XXX-XXX-XXXX',
        }),
    },
    monthlyCapAmount: {
      required: false,
      validate: value =>
        CAP_REGEX.test(value) ||
        t('LocalChangeEnrollmentForm.validationMessages.monthlyCapValid', {
          defaultValue: 'Please enter a valid dollar amount.',
        }),
    },
    optionID: { required: true },
  }

  const { register, reset, errors, watch, handleSubmit } = useForm({
    mode: 'onBlur',
  })
  const [alert, showAlert] = useState(null)
  const dismissAlert = useCallback(() => showAlert(null), [])

  const formID = 11

  const optionID = watch('optionID')
  const selectedOption = optionID ? find(capOptions, { id: Number(optionID) }) : null
  const hasSelectedCap = selectedOption && selectedOption.id === 2

  const [inProgress, setInProgress] = useState(false)
  const handleSubmitValid = async formData => {
    setInProgress(true)
    showAlert(false)

    try {
      const payload = {
        form_id: formID,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        monthly_cap_amount: formData.monthlyCapAmount,
        monthly_cap: formData.optionID.title,
      }

      const response = await axios.post(`${process.env.CONTACT_FORM_API_URL}/submit`, payload)

      if (response.status !== 200 || Boolean(response.data.error)) {
        throw new Error(`Unexpected status code: ${response.status} ${response.statusText}`)
      }
      reset()

      const cleanedUrl = window.location.href.replace(window.location.hash, '')
      navigate(`${cleanedUrl}#local-change`)
      setInProgress(false)
      showAlert({
        type: 'success',
        title: t('LocalChangeEnrollmentForm.successAlertTitle', {
          defaultValue: 'Thank you for enrolling!',
        }),
        message: t('LocalChangeEnrollmentForm.successAlertMessage', {
          defaultValue:
            'We will follow up with you in 2-5 business days to confirm your enrollment.',
        }),
      })
    } catch (error) {
      setInProgress(false)
      showAlert({
        type: 'error',
        title: t('LocalChangeEnrollmentForm.errorAlertTitle', { defaultValue: 'Error' }),
        message: t('LocalChangeEnrollmentForm.errorAlertMessage', {
          defaultValue:
            'Uh oh, looks like we’re having some internal server trouble. Please come back later and try again. For immediate help call us at 800-429-7626',
        }),
      })
    }
  }
  return (
    <Container>
      {alert && (
        <Row>
          <Col xs={12}>
            <Alert variant={alert.type} title={alert.title} onDismiss={dismissAlert}>
              {alert.message}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <div className={styles.formContainer}>
            <div className={styles.Card}>
              <Heading id="enroll-local-change-header" element="h2" size={3} tabIndex="0">
                {t('LocalChangeEnrollmentForm.headline', {
                  defaultValue: 'Enroll in the Round Up program',
                })}
              </Heading>
              <form action="/" method="POST" noValidate onSubmit={handleSubmit(handleSubmitValid)}>
                <Heading element="h3" size={4}>
                  {t('LocalChangeEnrollmentForm.memberInfo', {
                    defaultValue: 'Member Information',
                  })}
                </Heading>
                <div className={styles.fieldsContainer}>
                  <div className={styles.fieldWrapper}>
                    <TextField
                      label={`${t('LocalChangeEnrollmentForm.firstNameFieldLabel', {
                        defaultValue: 'First name',
                      })} (${t('LocalChangeEnrollmentForm.required', {
                        defaultValue: 'required',
                      })})`}
                      name="firstName"
                      ref={register(validationSchema.firstName)}
                      error={formatError(errors.firstName, validationSchema.firstName, t)}
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <TextField
                      label={`${t('LocalChangeEnrollmentForm.lastNameFieldLabel', {
                        defaultValue: 'Last name',
                      })} (${t('LocalChangeEnrollmentForm.required', {
                        defaultValue: 'required',
                      })})`}
                      name="lastName"
                      ref={register(validationSchema.lastName)}
                      error={formatError(errors.lastName, validationSchema.lastName, t)}
                    />
                  </div>
                </div>
                <div className={styles.fieldsContainer}>
                  <div className={styles.fieldWrapper}>
                    <TextField
                      type="email"
                      label={`${t('LocalChangeEnrollmentForm.emailFieldLabel', {
                        defaultValue: 'Email',
                      })} (${t('LocalChangeEnrollmentForm.required', {
                        defaultValue: 'required',
                      })})`}
                      name="email"
                      ref={register(validationSchema.email)}
                      error={formatError(errors.email, validationSchema.email, t)}
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    {/*
                     * maskPlaceholder is set to null because it was causing issues where
                     * the screen reader user's cursor would be put after the last placeholder
                     * character and then the user would be unable to type any characters.
                     */}
                    <InputMask maskPlaceholder={null} mask="999-999-9999">
                      <TextField
                        type="tel"
                        label={`${t('LocalChangeEnrollmentForm.phoneNumberFieldLabel', {
                          defaultValue: 'Phone number',
                        })} (${t('LocalChangeEnrollmentForm.required', {
                          defaultValue: 'required',
                        })})`}
                        id="phoneNumber"
                        name="phoneNumber"
                        autoComplete="tel"
                        ref={register(validationSchema.phoneNumber)}
                        error={formatError(errors.phoneNumber, validationSchema.phoneNumber, t)}
                      />
                    </InputMask>
                  </div>
                </div>
                <Heading element="h3" size={4}>
                  {t('LocalChangeEnrollmentForm.monthlyCapSectionTitle', {
                    defaultValue: 'Monthly Contribution Cap',
                  })}
                </Heading>
                <div className={styles.fieldsContainer}>
                  <div className={styles.fieldWrapper}>
                    <Select
                      name="optionID"
                      ref={register(validationSchema.optionID)}
                      error={formatError(errors.optionID, validationSchema.optionID, t)}
                      label={t('LocalChangeEnrollmentForm.setMonthlyCapFieldLabel', {
                        defaultValue: 'Do you want to set a monthly cap?',
                      })}
                    >
                      {capOptions.map(option => (
                        <Select.Option key={`${option.id}`} value={`${option.id}`}>
                          {option.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  {hasSelectedCap && (
                    <div className={styles.fieldWrapper}>
                      <TextField
                        label={t('LocalChangeEnrollmentForm.monthlyCapAmountFieldLabel', {
                          defaultValue: 'Please enter your monthly cap amount in dollars.',
                        })}
                        type="currency"
                        name="monthlyCapAmount"
                        ref={register(validationSchema.monthlyCapAmount)}
                        error={formatError(
                          errors.monthlyCapAmount,
                          validationSchema.monthlyCapAmount
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.formButtonContainer}>
                  <Button type="submit" disabled={inProgress} loading={inProgress}>
                    {t('LocalChangeEnrollmentForm.submitButtonLabel', {
                      defaultValue: 'Enroll in Round Up',
                    })}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export { LocalChangeEnrollmentForm }
export default LocalChangeEnrollmentForm
