import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Heading } from '../../../typography/Heading'
import { Button } from '../../../button'
import { Eyebrow } from '../../../typography/Eyebrow'
import * as styles from './TeamMemberCard.module.scss'
import { TeamMemberModal } from '../../../team-member-modal/TeamMemberModal'
import { Image } from '../../../image'
import { UnstyledLink } from '../../../typography/UnstyledLink'

/**
 * A 4 column component that features a team members profile and
 * allows for a user to call this individual, set up a meeting or
 * apply online. This card will be used for the mortgage team page
 * and potentially a variation of this component will be used on the
 * board of directors or the leadership team pages.
 * @param {*} param0
 */
const TeamMemberCard = ({
  firstName,
  lastName,
  teamMemberTitle,
  image,
  location,
  email,
  phoneNumber,
  richBody,
  servicesRichBody,
  licensesRichBody,
  valueStatementRichBody,
  teamMembers,
  nmlsNumber,
  cta1text,
  cta1Alt,
  cta1href,
  cta2text,
  cta2Alt,
  cta2href,
  isLink,
  learnMoreLink,
  headingElement = 'h3',
}) => {
  const [t] = useTranslation()

  /**
   * These constants control if the modal is showing
   * if the read more button is pressed it will open and
   * if the close button is pressed then the close const will be
   * called and close the modal
   */
  const [showDialog, setShowDialog] = useState(false)
  const open = useCallback(() => setShowDialog(true), [])
  const close = useCallback(() => setShowDialog(false), [])

  const imgSrcSets = [
    {
      height: 220,
    },
  ]

  /**
   * this returns the nested components and html used to render
   * the completed component.
   */
  return (
    <div className={styles.teamMemberCard} data-qa="team-member-card">
      <div className={styles.teamMemberCardInnerContainer}>
        <Image
          className={styles.Oval}
          type={image.file.contentType}
          src={image.file.url}
          alt={image.description}
          data-qa="team-member-card-image"
          srcSets={imgSrcSets}
        />

        <Heading element={headingElement} size={4}>
          {firstName}
          &nbsp;
          {lastName}
        </Heading>

        <div className={styles.titleContainer} data-qa="team-member-card-title">
          <Eyebrow className={styles.LengthyTitle}>
            <strong>{teamMemberTitle}</strong>
          </Eyebrow>
        </div>

        <div className={styles.LocationOffice} data-qa="team-member-card-location-container">
          {location && (
            <div className={styles.locationContainer} data-qa="team-member-card-location">
              {location}
            </div>
          )}

          {email && (
            <div>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}

          {phoneNumber && (
            <div>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </div>
          )}
        </div>

        {richBody && (
          <div className={styles.CardBody} data-qa="team-member-card-body">
            <p>
              {richBody.childMarkdownRemark && richBody.childMarkdownRemark.excerpt}
              {!isLink && !learnMoreLink && (
                <button
                  aria-label={t('TeamMemberCard.readMoreButtonAriaLabel', {
                    defaultValue: 'read more about {{firstName}} {{lastName}}',
                    firstName,
                    lastName,
                  })}
                  onClick={open}
                  className={styles.readMoreButton}
                  type="button"
                  data-qa="team-member-card-read-more"
                >
                  {t('TeamMemberCard.readMoreButtonLabel', { defaultValue: 'read more' })}
                </button>
              )}
              {isLink && learnMoreLink && (
                <UnstyledLink
                  aria-label={t('TeamMemberCard.readMoreButtonAriaLabel', {
                    defaultValue: 'read more about {{firstName}} {{lastName}}',
                    firstName,
                    lastName,
                  })}
                  to={learnMoreLink}
                  className={styles.readMoreButton}
                  data-qa="team-member-card-read-more"
                >
                  {t('TeamMemberCard.readMoreButtonLabel', { defaultValue: 'read more' })}
                </UnstyledLink>
              )}
            </p>

            <TeamMemberModal
              open={showDialog}
              onDismiss={close}
              firstName={firstName}
              lastName={lastName}
              location={location}
              nmlsNumber={nmlsNumber}
              teamMemberTitle={teamMemberTitle}
              image={image}
              cta1href={cta1href}
              cta1text={cta1text}
              cta1Alt={cta1Alt}
              cta2href={cta2href}
              cta2text={cta2text}
              cta2Alt={cta2Alt}
              email={email}
              phoneNumber={phoneNumber}
              richBody={richBody}
              servicesRichBody={servicesRichBody}
              licensesRichBody={licensesRichBody}
              valueStatementRichBody={valueStatementRichBody}
              teamMembers={teamMembers}
            />
            <div className={styles.nmlsContainer}>
              <p className={styles.NMLS}>{nmlsNumber}</p>
            </div>
          </div>
        )}

        {cta1text && cta1href && (
          <div data-qa="team-member-card-ctas">
            <div>
              <Button className={styles.CtaButton} url={cta1href} alt={cta1Alt}>
                {cta1text}
              </Button>
            </div>

            {cta2href && cta2text && (
              <div>
                <Button
                  variant="secondary"
                  url={cta2href}
                  className={styles.Cta2Button}
                  alt={cta2Alt}
                >
                  {cta2text}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
/**
 * This is validating all of the props that are passed to this
 * component.
 */
TeamMemberCard.propTypes = {
  richBody: PropTypes.object,
  servicesRichBody: PropTypes.object,
  licensesRichBody: PropTypes.object,
  valueStatementRichBody: PropTypes.object,
  teamMembers: PropTypes.array,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  cta1href: PropTypes.string,
  cta1Alt: PropTypes.string,
  cta1text: PropTypes.string,
  cta2href: PropTypes.string,
  cta2text: PropTypes.string,
  cta2Alt: PropTypes.string,
  teamMemberTitle: PropTypes.string.isRequired,
  location: PropTypes.string,
  nmlsNumber: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  image: PropTypes.object.isRequired,
  isLink: PropTypes.bool,
  learnMoreLink: PropTypes.string,
  headingElement: PropTypes.string,
}

export { TeamMemberCard }
export default TeamMemberCard
