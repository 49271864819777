import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import classNames from 'classnames'
import * as styles from './TeamMemberCardModule.module.scss'
import { Container, Row, Col } from '../../grid'
import { TeamMemberCard } from './components'
import { Heading } from '../../typography/Heading'
import { Button } from '../../button'

/**
 * This module is used to map the team member card component to the screen
 * so that when the screen is resized the appropriate number of cards are shown
 * per line and they do not overlap. This module also adds the optional
 * headline for pages like leadership & Board.
 */

const TeamMemberCardModule = ({
  headline,
  ctaHref,
  ctaTitle,
  ctaAlt,
  teamMemberCardModules,
  headerLevel = 2,
}) => {
  const moduleRows = chunk(teamMemberCardModules, 12)
  const headlineClasses = classNames(styles.headline, styles.centered)
  const moduleHeadlineLevel = headline ? headerLevel : headerLevel - 1
  const moduleHeadlineNextLevel = moduleHeadlineLevel + 1

  return (
    <Container>
      {headline && (
        <Row>
          <Col>
            <Heading element={`h${moduleHeadlineLevel}`} size={3} className={headlineClasses}>
              {headline}
            </Heading>
          </Col>
        </Row>
      )}

      {moduleRows.map((rows, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row className={styles.cardsWrapper} key={index} data-qa="team-member-module-container">
          {rows.map(teamMember => (
            <TeamMemberCard
              key={`${teamMember.firstName}-${teamMember.lastName}`}
              headingElement={`h${moduleHeadlineNextLevel}`}
              {...teamMember}
            />
          ))}
        </Row>
      ))}
      {ctaHref && ctaTitle && (
        <Row>
          <div className={styles.buttonDiv} data-qa="team-member-module-bottom-button">
            <Button url={ctaHref} alt={ctaAlt}>
              {ctaTitle}
            </Button>
          </div>
        </Row>
      )}
    </Container>
  )
}

TeamMemberCardModule.propTypes = {
  headline: PropTypes.string,
  headerLevel: PropTypes.number,
  teamMemberCardModules: PropTypes.array,
  ctaHref: PropTypes.string,
  ctaTitle: PropTypes.string,
  ctaAlt: PropTypes.string,
}

export { TeamMemberCardModule }
export default TeamMemberCardModule
