import PropTypes from 'prop-types'
import { Layout } from '../components/layouts/layout'
import { PageMeta } from '../components/page-meta'
import { Container, Row, Col } from '../components/grid'

import { GetSectionItem } from './sectionMapping'

const GetSections = ({ sections, menu }) => {
  const data = sections.map((section, index) => {
    const { items } = section
    const { length } = items

    switch (length) {
      case 3:
        return (
          // eslint-disable-next-line react/no-array-index-key
          <section key={`section-${index}`} id={`section-${index}`} className="section">
            <div id={`section-${section.id}`} />
            <Container>
              <Row>
                {items.map((item, col) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Col key={`section-${index}-${col}`} xs={12} md={4}>
                    <GetSectionItem item={item} index={index} menu={menu} />
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
        )
      case 2:
        return (
          // eslint-disable-next-line react/no-array-index-key
          <section key={`section-${index}`} id={`section-${index}`} className="section">
            <div id={`section-${section.id}`} />
            <Container>
              <Row>
                {items.map((item, col) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Col key={`section-${index}-${col}`} xs={12} md={6}>
                    <GetSectionItem item={item} index={index} menu={menu} />
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
        )
      case 1:
      default:
        return (
          // eslint-disable-next-line react/no-array-index-key
          <section key={`section-${index}`} id={`section-${index}`} className="section">
            <div id={`section-${section.id}`} />
            {items.map(item => (
              // eslint-disable-next-line react/no-array-index-key
              <GetSectionItem item={item} index={index} key={item.id} menu={menu} />
            ))}
          </section>
        )
    }
  })

  return data
}

const PageTemplate = props => {
  const { location, pageContext } = props
  const { navMenus, pageData } = pageContext
  const { sections, showSubnav, showSubnavOnScroll } = pageData

  return (
    <Layout
      location={location}
      pageContext={pageContext}
      menu={navMenus}
      showSubnav={showSubnav}
      showSubnavOnScroll={showSubnavOnScroll}
    >
      <PageMeta
        language={pageData.language}
        title={pageData.title}
        keywords={pageData.seoKeywords}
        description={pageData.seoDescription}
        image={pageData.ogImage}
        pathname={pageData.urlPath}
        robots={pageData.robotsMetaTag}
        canonicalUrlOverride={pageData.canonicalUrlOverride}
      />

      <GetSections
        sections={sections}
        pageContext={pageContext}
        location={location}
        menu={navMenus}
      />
    </Layout>
  )
}

GetSectionItem.propTypes = {
  item: PropTypes.object,
}

PageTemplate.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default PageTemplate
