/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Heading } from '../../typography/Heading'
import { RichText } from '../../rich-text'
import { Image } from '../../image'
import { Container, Row, Col } from '../../grid'
import * as styles from './ImageCopy25Module.module.scss'

const ImageCopy25Module = ({ richHeadline, image, imagePosition }) => {
  const imgSrcSets = [
    {
      width: 350,
      media: '(min-width: 1140px)',
    },
    {
      width: 700,
    },
  ]

  if (imagePosition === 'right') {
    return (
      <Container>
        <Row>
          <Col xs={12} lg={6} xl={8}>
            <div className={styles.contentContainer}>
              <RichText
                overrides={{
                  paragraph: (node, children) =>
                    !isEmpty(children) && (
                      <Heading element="p" size={3}>
                        {children}
                      </Heading>
                    ),
                }}
              >
                {richHeadline}
              </RichText>
            </div>
          </Col>

          <Col xs={12} lg={6} xl={4}>
            <div className={styles.imageContainer}>
              <Image
                type={image.contentType}
                src={image.url}
                alt={image.description}
                data-qa="image-copy-25-image-right"
                srcSets={imgSrcSets}
              />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Col xs={12} lg={6} xl={4}>
          <div className={styles.imageContainer}>
            <Image
              type={image.contentType}
              src={image.url}
              alt={image.description}
              data-qa="image-copy-25-image-left"
              srcSets={imgSrcSets}
            />
          </div>
        </Col>

        <Col xs={12} lg={6} xl={8}>
          <div className={styles.contentContainer}>
            <RichText
              overrides={{
                paragraph: (node, children) =>
                  !isEmpty(children) && (
                    <Heading element="p" size={3}>
                      {children}
                    </Heading>
                  ),
              }}
            >
              {richHeadline}
            </RichText>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

ImageCopy25Module.propTypes = {
  richHeadline: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  imagePosition: PropTypes.string.isRequired,
}

export { ImageCopy25Module }
export default ImageCopy25Module
