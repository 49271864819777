import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { Eyebrow } from '../../../typography/Eyebrow'
import { Heading } from '../../../typography/Heading'
import { Image } from '../../../image/Image'
import { Link } from '../../../typography/Link/Link'
import { Col } from '../../../grid'
import { RichText } from '../../../rich-text'
import { Button } from '../../../button'
import { Stack } from '../../../stack'
import { LiveEngageButton } from '../../../live-engage-button'

import * as styles from './Card.module.scss'

const Card = ({
  id,
  image,
  richHeadline,
  richTagline,
  richBody,
  linkUrl,
  linkCta,
  linkAlt,
  buttonUrl,
  buttonCta,
  buttonAlt,
  disclaimer,
  borderColor,
  columnWidth,
  centered,
  eyebrowElement,
  taglineElement,
  showBoxShadow,
  isButtonModal,
  modalUrlNonMember,
  modalUrlIsMember,
  ...props
}) => {
  let borderClass = ''

  if (borderColor) {
    switch (borderColor) {
      case 'dark-sky-blue':
        borderClass = 'darkSkyBlue'
        break
      case 'dark-lime-green':
        borderClass = 'darkLimeGreen'
        break
      case 'cerulean-two':
        borderClass = 'ceruleanTwo'
        break
      default:
        borderClass = ''
    }
  }

  const moduleClasses = classNames(
    styles.Card,
    centered && styles.centered,
    styles[borderClass],
    columnWidth === 6 ? styles.twoCol : styles.threeCol,
    showBoxShadow && styles.boxShadow
  )

  /* If we're in a three-column layout, having side-by-side is cramped on a medium device.
   * Therefore, a medium device is okay to have two columns, so we need this goofy bit of logic.
   */
  const breakpoints = {
    xs: 12,
    md: columnWidth === 6 ? 6 : 12,
    lg: columnWidth,
  }

  const hasLink = linkUrl && linkCta
  const hasButton = buttonUrl && buttonCta

  /* We need to remove the bottom margin from an element if none of the elements below it
   * are displaying.  We assume the image will always be accompanied by text, so doesn't receive
   * this treatment.  The link has no extra bottom padding to begin with.
   */
  const headlineStyle = classNames(
    styles.headline,
    !richTagline && !hasLink && !hasButton && !richBody && styles.noMarginBottom
  )
  const taglineStyle = classNames(
    styles.copy,
    !hasLink && !hasButton && !richBody && styles.noMarginBottom
  )
  const bodyStyle = classNames(styles.body, !hasLink && !hasButton && styles.noMarginBottom)
  // This allows for links to render the chat with us liveperson.com module.
  const hasChatLink = linkUrl && linkUrl.includes('#chat-with-us')

  const dataQA = centered ? 'card-module-card-centered' : 'card-module-card'

  const imgSrcSets = [
    {
      height: 96,
    },
  ]

  return (
    <Col key={id} data-qa={dataQA} {...breakpoints} {...props}>
      <div className={moduleClasses}>
        {image && image.url && image.title && (
          <div className={styles.imageContainer}>
            <Image
              className={styles.image}
              type={image.contentType}
              src={image.url}
              alt={image.description}
              data-qa="card-module-card-image"
              srcSets={imgSrcSets}
            />
          </div>
        )}
        {richHeadline && (
          <RichText
            overrides={{
              paragraph: (node, children) =>
                !isEmpty(children) && (
                  <Eyebrow
                    element={eyebrowElement}
                    className={headlineStyle}
                    data-qa="card-module-card-eyebrow"
                  >
                    <strong>{children}</strong>
                  </Eyebrow>
                ),
            }}
          >
            {richHeadline}
          </RichText>
        )}
        {richTagline && (
          <RichText
            overrides={{
              paragraph: (node, children) =>
                !isEmpty(children) && (
                  <Heading
                    element={taglineElement}
                    className={taglineStyle}
                    data-qa="card-module-card-tagline"
                  >
                    {children}
                  </Heading>
                ),
            }}
          >
            {richTagline}
          </RichText>
        )}
        {richBody && (
          <div className={bodyStyle} data-qa="card-module-card-body">
            <RichText>{richBody}</RichText>
          </div>
        )}
        {hasButton && isButtonModal && (
          <Button
            alt={buttonAlt}
            className={styles.button}
            isModal={isButtonModal}
            modalUrlIsMember={modalUrlIsMember}
            modalUrlNonMember={modalUrlNonMember}
          >
            {buttonCta}
          </Button>
        )}
        {hasButton && !isButtonModal && (
          <Button url={buttonUrl} alt={buttonAlt} className={styles.button}>
            {buttonCta}
          </Button>
        )}
        {hasLink && !hasChatLink && (
          <Link to={linkUrl} alt={linkAlt} data-qa="card-module-card-link" className={styles.link}>
            {linkCta}
          </Link>
        )}
        {hasChatLink && (
          <Stack distribution="center">
            <LiveEngageButton
              buttonId={process.env.LIVEENGAGE_CONTACT_US_BUTTON_ID}
              className={classNames(styles.LiveChatContainer, styles.link)}
              data-qa="card-module-card-link"
              role="button"
            />
          </Stack>
        )}
        {disclaimer && (
          <div className={styles.disclaimer} data-qa="card-module-card-disclaimer">
            <RichText>{disclaimer}</RichText>
          </div>
        )}
      </div>
    </Col>
  )
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.object,
  richHeadline: PropTypes.object,
  richTagline: PropTypes.object.isRequired,
  richBody: PropTypes.object,
  linkUrl: PropTypes.string,
  linkCta: PropTypes.string,
  linkAlt: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonCta: PropTypes.string,
  buttonAlt: PropTypes.string,
  disclaimer: PropTypes.object,
  borderColor: PropTypes.oneOf(['dark-sky-blue', 'dark-lime-green', 'cerulean-two']),
  columnWidth: PropTypes.oneOf([4, 6]).isRequired,
  centered: PropTypes.bool,
  eyebrowElement: PropTypes.string,
  taglineElement: PropTypes.string,
  showBoxShadow: PropTypes.bool,
  modalUrlIsMember: PropTypes.string,
  modalUrlNonMember: PropTypes.string,
  isButtonModal: PropTypes.bool,
}

export { Card }
export default Card
