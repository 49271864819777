// extracted by mini-css-extract-plugin
export var DialogOverlay = "TeamMemberModal-module--DialogOverlay--2KFZ5";
export var DialogContent = "TeamMemberModal-module--DialogContent--3WISw";
export var CloseButton = "TeamMemberModal-module--CloseButton--3DIRP";
export var Oval = "TeamMemberModal-module--Oval--1yy1l";
export var CtaButton = "TeamMemberModal-module--CtaButton--2IHvI";
export var readMoreButton = "TeamMemberModal-module--readMoreButton--2G9lE";
export var LengthyTitle = "TeamMemberModal-module--LengthyTitle--3NxFA";
export var UpperModalContainer = "TeamMemberModal-module--UpperModalContainer--3mGkp";
export var ImageContainer = "TeamMemberModal-module--ImageContainer--1kad1";
export var modalTitleContainer = "TeamMemberModal-module--modalTitleContainer--mdE_X";
export var ModalTitle = "TeamMemberModal-module--ModalTitle--164Ry";
export var HeadingStyle = "TeamMemberModal-module--HeadingStyle--25j0J";
export var NMLS = "TeamMemberModal-module--NMLS--Maz49";
export var TeamMemberModalBody = "TeamMemberModal-module--TeamMemberModalBody--2S77M";
export var upperImageTextContainer = "TeamMemberModal-module--upperImageTextContainer--26Fas";
export var Section = "TeamMemberModal-module--Section--IuRRL";
export var SectionTitle = "TeamMemberModal-module--SectionTitle--FMhvl";
export var TeamMemberTitle = "TeamMemberModal-module--TeamMemberTitle--3l_aX";
export var Cta2Button = "TeamMemberModal-module--Cta2Button--1L9Kr";
export var buttonContainer = "TeamMemberModal-module--buttonContainer--1WMiw";
export var titleContainer = "TeamMemberModal-module--titleContainer--D4N0p";
export var TeamMembersList = "TeamMemberModal-module--TeamMembersList--2Ls1e";