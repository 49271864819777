// extracted by mini-css-extract-plugin
export var imageContainerLeft = "ImageCopyModule-module--imageContainerLeft--1NInp";
export var imageContainerRight = "ImageCopyModule-module--imageContainerRight--1Gvbt";
export var imageContainerTop = "ImageCopyModule-module--imageContainerTop--2mYNn";
export var contentContainer = "ImageCopyModule-module--contentContainer--jmOHE";
export var contentContainerInner = "ImageCopyModule-module--contentContainerInner--HoxSQ";
export var imageTextModuleTop = "ImageCopyModule-module--imageTextModuleTop--2SM_m";
export var contentInnerBottom = "ImageCopyModule-module--contentInnerBottom--1SmlN";
export var tagline = "ImageCopyModule-module--tagline--2E1cY";
export var taglineLight = "ImageCopyModule-module--taglineLight--1YPqI";
export var heading = "ImageCopyModule-module--heading--3dkQY";
export var headingLight = "ImageCopyModule-module--headingLight--2XT1k";
export var copy = "ImageCopyModule-module--copy--3zF3p";
export var copyLight = "ImageCopyModule-module--copyLight--FyAMl";
export var buttonRow = "ImageCopyModule-module--buttonRow--3XrSS";
export var disclaimer = "ImageCopyModule-module--disclaimer--1tf-L";
export var caption = "ImageCopyModule-module--caption--yYMc9";
export var imageContainerRightColumn = "ImageCopyModule-module--imageContainerRightColumn--p6g4U";