// extracted by mini-css-extract-plugin
export var Card = "Card-module--Card--1xoS-";
export var imageContainer = "Card-module--imageContainer--1RFfj";
export var image = "Card-module--image--3KzYK";
export var headline = "Card-module--headline--1hE7K";
export var copy = "Card-module--copy--2viIp";
export var body = "Card-module--body--2qShi";
export var noMarginBottom = "Card-module--noMarginBottom--2bEfg";
export var button = "Card-module--button--UU3N5";
export var disclaimer = "Card-module--disclaimer--247DQ";
export var boxShadow = "Card-module--boxShadow--2FaOd";
export var centered = "Card-module--centered--3U_aL";
export var twoCol = "Card-module--twoCol--2EjVN";
export var threeCol = "Card-module--threeCol--1fLTC";
export var darkSkyBlue = "Card-module--darkSkyBlue--3inSG";
export var link = "Card-module--link--3qTYv";
export var darkLimeGreen = "Card-module--darkLimeGreen--1FmdA";
export var ceruleanTwo = "Card-module--ceruleanTwo--3fmvm";
export var LiveChatContainer = "Card-module--LiveChatContainer--gEECk";
export var LegacyLiveChatContainer = "Card-module--LegacyLiveChatContainer--3HDGA";