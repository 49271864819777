// extracted by mini-css-extract-plugin
export var teamMemberCard = "TeamMemberCard-module--teamMemberCard--1FCeT";
export var teamMemberCardInnerContainer = "TeamMemberCard-module--teamMemberCardInnerContainer--1Fe1r";
export var Oval = "TeamMemberCard-module--Oval--3ILf9";
export var LongerfirstnameLong = "TeamMemberCard-module--LongerfirstnameLong--ylTIx";
export var LengthyTitle = "TeamMemberCard-module--LengthyTitle--3PHtO";
export var LocationOffice = "TeamMemberCard-module--LocationOffice--1xR-i";
export var textStyle1 = "TeamMemberCard-module--text-style-1--5S49u";
export var CardBody = "TeamMemberCard-module--CardBody--3irfN";
export var CtaButton = "TeamMemberCard-module--CtaButton--2RDRB";
export var Cta2Button = "TeamMemberCard-module--Cta2Button--298ts";
export var ImageContainer = "TeamMemberCard-module--ImageContainer--1wKi8";
export var readMoreButton = "TeamMemberCard-module--readMoreButton--3Du7C";
export var titleContainer = "TeamMemberCard-module--titleContainer--Wz3TB";
export var locationContainer = "TeamMemberCard-module--locationContainer--XIBjf";
export var separatorContainer = "TeamMemberCard-module--separatorContainer--2-yrv";
export var NMLS = "TeamMemberCard-module--NMLS--Le6PU";
export var nmlsContainer = "TeamMemberCard-module--nmlsContainer--3_4H0";