import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from '@reach/router'
import { useHotkeys } from 'react-hotkeys-hook'
import { Heading } from '../../typography/Heading'
import { RichText } from '../../rich-text'
import { Container } from '../../grid'
import { Accordion } from '../../accordion'
import { Image } from '../../image'
import * as styles from './AccordionFaq.module.scss'

const AccordionFaq = ({ richHeadline, faqs, bigger, id }) => {
  const [indices, setIndices] = useState([])
  const headerClass = classNames(bigger && styles.BiggerQuestion)
  const location = useLocation()
  useEffect(() => {
    const { search } = location

    if (search !== '' && search.includes('faq')) {
      // Sort the array so we can scroll to the lowest number first
      const sortAsc = (a, b) => {
        return a - b
      }
      const openItemsArr = search
        .replace('?faq=', '')
        .split(',')
        .map(item => {
          return Number(item)
        })
        .sort(sortAsc)

      const firstItemId = document.getElementById(`faq-${openItemsArr[0]}`)

      window.scrollTo({
        top: firstItemId.offsetTop - 100,
        behavior: 'smooth',
      })

      setIndices(openItemsArr)
    }
  }, [])

  function toggleItem(toggledIndex) {
    let newIndices
    if (indices.includes(toggledIndex)) {
      newIndices = indices.filter(currentIndex => currentIndex !== toggledIndex)
    } else {
      newIndices = [...indices, toggledIndex].sort()
    }

    // sync the state here
    setIndices(newIndices)
    window.history.replaceState('', '', `?faq=${newIndices}`)
  }

  useHotkeys('meta+f, ctrl+f', () => {
    const indexes = []
    for (let i = 0; i < faqs.length; i += 1) {
      indexes.push(i)
    }
    setIndices(indexes)
    window.history.replaceState('', '', `?faq=${indexes}`)
  })

  return (
    <Container>
      <div className={styles.AccordionFaq}>
        <RichText
          overrides={{
            paragraph: (node, children) =>
              !isEmpty(children) && (
                <Heading className={styles.FaqHeader} element="h2" size={3}>
                  {children}
                </Heading>
              ),
          }}
        >
          {richHeadline}
        </RichText>
        {/* eslint-disable-next-line */}
        <Accordion id={`faq-${id}`} onChange={toggleItem} index={indices}>
          {faqs.map((faq, index) => {
            return (
              <Accordion.Item key={faq.question} id={`faq-${index}`}>
                <Accordion.Header>
                  <span className={headerClass}>{faq.question}</span>
                </Accordion.Header>
                <Accordion.Panel>
                  <RichText>{faq.answer}</RichText>
                  {faq.image && (
                    <Image
                      type={faq.image.contentType}
                      src={faq.image.url}
                      alt={faq.image.description}
                    />
                  )}
                  {faq.disclaimer && (
                    <div className={styles.disclaimer}>
                      <RichText>{faq.disclaimer}</RichText>
                    </div>
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            )
          })}
        </Accordion>
      </div>
    </Container>
  )
}

AccordionFaq.propTypes = {
  richHeadline: PropTypes.object,
  faqs: PropTypes.arrayOf(PropTypes.object),
  bigger: PropTypes.bool,
  id: PropTypes.string,
}

export { AccordionFaq }
export default AccordionFaq
