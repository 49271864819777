import { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Heading } from '../typography/Heading'
import { RichText } from '../rich-text'
import { Button } from '../button'
import { Eyebrow } from '../typography/Eyebrow'
import { Image } from '../image'
import '@reach/dialog/styles.css'
import * as styles from './TeamMemberModal.module.scss'
import CloseImage from '../../images-global/close.svg'

/**
 * This modal shows when a user clicks on the read more indicator which is shown
 * if the body of the team member card is too long. This modal takes in an image src
 * a href to the make an appointment screen as well as the text about the team
 * member and the aria required alt text and label for the button.
 * @param {*} param0
 */
const TeamMemberModal = ({
  open,
  onDismiss,
  richBody,
  servicesRichBody,
  licensesRichBody,
  valueStatementRichBody,
  teamMembers,
  image,
  firstName,
  lastName,
  email,
  phoneNumber,
  location,
  teamMemberTitle,
  nmlsNumber,
  cta1text,
  cta1Alt,
  cta1href,
  cta2text,
  cta2Alt,
  cta2href,
}) => {
  const [t] = useTranslation()
  const imgSrcSets = [
    {
      height: 220,
    },
  ]

  return (
    <DialogOverlay className={styles.DialogOverlay} isOpen={open} onDismiss={onDismiss}>
      <DialogContent
        className={styles.DialogContent}
        aria-label={t('TeamMemberModal.ariaLabel', {
          defaultValue: `Team member detail card for {{firstName}} {{lastName}}`,
          firstName,
          lastName,
        })}
        data-qa="team-member-modal"
      >
        <Fragment>
          <div className={styles.UpperModalContainer} data-qa="team-member-modal-upper">
            <button
              type="button"
              className={styles.CloseButton}
              onClick={onDismiss}
              data-qa="team-member-modal-close-button"
            >
              <img
                src={CloseImage}
                alt={t('TeamMemberModal.closeButtonLabel', { defaultValue: 'Close' })}
              />
            </button>

            <div className={styles.upperImageTextContainer}>
              <div className={styles.ImageContainer} data-qa="team-member-modal-image-container">
                <Image
                  className={styles.Oval}
                  type={image.file.contentType}
                  src={image.file.url}
                  alt={image.description}
                  srcSets={imgSrcSets}
                />
              </div>
              <div className={styles.modalTitleContainer}>
                <div className={styles.ModalTitle} data-qa="team-member-modal-title">
                  <Heading element="h1" size={4} className={styles.HeadingStyle}>
                    {firstName}
                    &nbsp;
                    {lastName}
                  </Heading>
                </div>
                <div className={styles.titleContainer} data-qa="team-member-modal-title-container">
                  <Eyebrow className={styles.LengthyTitle}>
                    <strong>{teamMemberTitle}</strong>
                  </Eyebrow>
                </div>

                <div data-qa="team-member-modal-location-container">
                  {location && (
                    <Fragment>
                      {location}
                      <br aria-hidden="true" />
                    </Fragment>
                  )}

                  {email && <a href={`mailto:${email}`}>{email}</a>}
                  {email && phoneNumber && <span>&nbsp;&#8226;&nbsp;</span>}
                  {phoneNumber && <a href={`tel:/${phoneNumber}`}>{phoneNumber}</a>}
                </div>

                {nmlsNumber && (
                  <p className={styles.NMLS} data-qa="team-member-modal-location-nlms">
                    {nmlsNumber}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.TeamMemberModalBody}>
            {richBody && richBody.childMarkdownRemark && (
              <section
                className={styles.Section}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: richBody.childMarkdownRemark.html }}
              />
            )}

            {servicesRichBody && servicesRichBody.json && (
              <section className={styles.Section}>
                <Eyebrow element="h2" className={styles.SectionTitle}>
                  {t('TeamMemberModal.servicesSectionTitle', {
                    defaultValue: `Services`,
                  })}
                </Eyebrow>
                <RichText>{servicesRichBody.json}</RichText>
              </section>
            )}

            {licensesRichBody && licensesRichBody.json && (
              <section className={styles.Section}>
                <Eyebrow element="h2" className={styles.SectionTitle}>
                  {t('TeamMemberModal.licensesSectionTitle', {
                    defaultValue: `Licenses`,
                  })}
                </Eyebrow>
                <RichText>{licensesRichBody.json}</RichText>
              </section>
            )}

            {valueStatementRichBody && valueStatementRichBody.json && (
              <section className={styles.Section}>
                <Eyebrow element="h2" className={styles.SectionTitle}>
                  {t('TeamMemberModal.valueStatementSectionTitle', {
                    defaultValue: `Value Statement`,
                  })}
                </Eyebrow>
                <RichText>{valueStatementRichBody.json}</RichText>
              </section>
            )}

            {teamMembers && teamMembers.length > 0 && (
              <section className={styles.Section}>
                <Eyebrow element="h2" className={styles.SectionTitle}>
                  {t('TeamMemberModal.teamMembersSectionTitle', {
                    defaultValue: `{{firstName}}'s Team Members`,
                    firstName,
                  })}
                </Eyebrow>

                <div className={styles.TeamMembersList}>
                  {teamMembers.map(teamMember => (
                    <article>
                      <Heading element="h3" size={4} className={styles.HeadingStyle}>
                        {teamMember.firstName}
                        &nbsp;
                        {teamMember.lastName}
                      </Heading>
                      <Eyebrow className={classNames(styles.LengthyTitle, styles.TeamMemberTitle)}>
                        {teamMember.teamMemberTitle}
                      </Eyebrow>
                      {teamMember.email && (
                        <a href={`mailto:${teamMember.email}`}>{teamMember.email}</a>
                      )}
                      {teamMember.email && teamMember.phoneNumber && (
                        <span>&nbsp;&#8226;&nbsp;</span>
                      )}
                      {teamMember.phoneNumber && (
                        <a href={`tel:/${teamMember.phoneNumber}`}>{teamMember.phoneNumber}</a>
                      )}
                    </article>
                  ))}
                </div>
              </section>
            )}

            {cta1text && cta1href && (
              <div className={styles.buttonContainer} data-qa="team-member-modal-bottom-container">
                <Button
                  className={styles.CtaButton}
                  url={cta1href}
                  data-qa="team-member-modal-left-button"
                  alt={cta1Alt}
                >
                  {cta1text}
                </Button>

                {cta2href && cta2text && (
                  <Button
                    variant="secondary"
                    url={cta2href}
                    className={styles.Cta2Button}
                    alt={cta2Alt}
                    data-qa="team-member-modal-right-button"
                  >
                    {cta2text}
                  </Button>
                )}
              </div>
            )}
          </div>
        </Fragment>
      </DialogContent>
    </DialogOverlay>
  )
}

TeamMemberModal.propTypes = {
  richBody: PropTypes.object,
  servicesRichBody: PropTypes.object,
  licensesRichBody: PropTypes.object,
  valueStatementRichBody: PropTypes.object,
  teamMembers: PropTypes.array,
  onDismiss: PropTypes.func,
  open: PropTypes.bool,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  cta1href: PropTypes.string,
  cta1text: PropTypes.string,
  cta1Alt: PropTypes.string,
  cta2href: PropTypes.string,
  cta2text: PropTypes.string,
  cta2Alt: PropTypes.string,
  teamMemberTitle: PropTypes.string.isRequired,
  location: PropTypes.string,
  nmlsNumber: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  image: PropTypes.object.isRequired,
}

export { TeamMemberModal }
export default TeamMemberModal
