// extracted by mini-css-extract-plugin
export var iFrameContainerLeft = "VideoCopyModule-module--iFrameContainerLeft--3LpXy";
export var iFrameContainerRight = "VideoCopyModule-module--iFrameContainerRight--16j8J";
export var videoCopyModuleTop = "VideoCopyModule-module--videoCopyModuleTop--qoTJT";
export var innerIFrameContainer = "VideoCopyModule-module--innerIFrameContainer--3bh-y";
export var iFrame = "VideoCopyModule-module--iFrame--3BjJj";
export var contentContainer = "VideoCopyModule-module--contentContainer--21Xhc";
export var contentContainerInner = "VideoCopyModule-module--contentContainerInner--yXGAr";
export var contentInnerBottom = "VideoCopyModule-module--contentInnerBottom--3Gzot";
export var tagline = "VideoCopyModule-module--tagline--3TJuQ";
export var heading = "VideoCopyModule-module--heading--3V25e";
export var copy = "VideoCopyModule-module--copy--3SIHs";
export var buttonRow = "VideoCopyModule-module--buttonRow--1VjvU";
export var disclaimer = "VideoCopyModule-module--disclaimer--2t9yj";
export var partialBackground = "VideoCopyModule-module--partialBackground--hHtEp";
export var iFrameContainerRightColumn = "VideoCopyModule-module--iFrameContainerRightColumn--18sjq";