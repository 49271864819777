import { useState, useEffect } from 'react'
import axios from 'axios'
import intersection from 'lodash/intersection'
import invokeMap from 'lodash/invokeMap'

const normalizeTags = tags => invokeMap(tags, String.prototype.toLowerCase)

function useTaggedEvent(requiredTags) {
  const [upcomingEvent, setUpcomingEvent] = useState(null)
  const [status, setUpcomingEventStatus] = useState('loading')

  useEffect(() => {
    const fetchData = async () => {
      try {
        setUpcomingEventStatus('loading')

        const result = await axios({
          method: 'get',
          url: process.env.UPCOMING_EVENT_API_URL,
        })

        // Filters events that match all tags
        const matchingEvents = result.data.results.filter(
          event => intersection(normalizeTags(requiredTags), normalizeTags(event.tags)).length > 0
        )

        setUpcomingEvent(matchingEvents[0] || null)
        setUpcomingEventStatus('loaded')
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Upcoming event could not be loaded')
        setUpcomingEventStatus('error')
      }
    }
    fetchData()
  }, [requiredTags])

  return [upcomingEvent, status]
}

export { useTaggedEvent }
