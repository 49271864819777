import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faPhoneAlt } from '@fortawesome/pro-regular-svg-icons'
import { RichText } from 'components/rich-text'
import { Heading } from '../../typography/Heading'
import { Button } from '../../button'
import { Eyebrow } from '../../typography/Eyebrow'
import * as styles from './HeaderBioModule.module.scss'
import { Image } from '../../image'
import { UnstyledLink } from '../../typography/UnstyledLink'

/**
 * A module that showcases each member of the mortgage team this will replace the team member modal
 * It will show their contact information, where they are based out of as well as any licenses, services
 * and their bio. This module is part of the larger mortgage team member page which will include an apply
 * now as well as a way for users to schedule an appointment with the selected mortgage team member.
 * @param {*} param0
 */
const HeaderBioModule = ({
  firstName,
  lastName,
  teamMemberTitle,
  image,
  location,
  email,
  richBody,
  phoneNumber,
  services,
  licenses,
  valueStatement,
  nmlsNumber,
  lmbNumber,
  cta1Text,
  cta1AltText,
  cta1Url,
  cta2Text,
  cta2AlternativeText,
  cta2Url,
  headingElement = 'h3',
}) => {
  const [t] = useTranslation()

  const imgSrcSets = [
    {
      height: 400,
    },
  ]

  return (
    <div className={styles.HeaderBioModule} data-qa="header-bio">
      <div className={styles.HeaderBioModuleInnerContainer}>
        <div className={styles.linkImageContainer}>
          <div className={styles.mortgageTeamLink}>
            <UnstyledLink to="/home-lending/mortgage-team" className={styles.backToLink}>
              &lt;
              {t('HeaderBioModule.BackToMortgageTeam', {
                defaultValue: 'Back to Mortgage Team',
              })}
            </UnstyledLink>
          </div>
          <Image
            className={styles.Oval}
            type={image.contentType}
            src={image.url}
            alt={image.description}
            data-qa="header-bio-image"
            srcSets={imgSrcSets}
          />
        </div>
        <div className={styles.titleContainer} data-qa="header-bio-title">
          <div className={styles.title}>
            <Heading element="h1" className={styles.LengthyTitle}>
              {teamMemberTitle}
            </Heading>
          </div>
          <div className={styles.nameContainer}>
            <Heading size={4} element="h2" className={styles.headerBioFirstLastName}>
              {firstName}
              &nbsp;
              {lastName}
            </Heading>
          </div>
          <div className={styles.nmlsContainer}>
            <p className={styles.NMLS}>
              {nmlsNumber} | {lmbNumber}
            </p>
          </div>
          {phoneNumber && (
            <div className={styles.phoneNumberContainer}>
              <FontAwesomeIcon icon={faPhoneAlt} className={styles.phoneIcon} />
              <a className={styles.phoneNumber} href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>
            </div>
          )}
          {email && (
            <div>
              <FontAwesomeIcon icon={faEnvelope} className={styles.phoneIcon} />
              <a className={styles.email} href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          )}
          {cta1Text && cta1Url && (
            <div data-qa="header-bio-ctas" className={styles.ctaContainer}>
              <div>
                <Button
                  className={styles.CtaButton}
                  variant="light"
                  url={cta1Url}
                  alt={cta1AltText}
                >
                  {cta1Text}
                </Button>
              </div>
              {cta2Url && cta2Text && (
                <div className={styles.linkContainer}>
                  <UnstyledLink
                    to={cta2Url}
                    className={styles.Cta2Button}
                    alt={cta2AlternativeText}
                  >
                    {cta2Text}
                  </UnstyledLink>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.headerBioAbout}>
        <Heading element={headingElement} size={5} className={styles.aboutHeader}>
          {t('HeaderBioModule.about', { defaultValue: 'About' })} {firstName}
        </Heading>
        {richBody && (
          <div className={styles.aboutBody} data-qa="header-bio-body">
            <RichText>{richBody}</RichText>
          </div>
        )}
      </div>
    </div>
  )
}
/**
 * This is validating all of the props that are passed to this
 * component.
 */
HeaderBioModule.propTypes = {
  richBody: PropTypes.object,
  services: PropTypes.object,
  licenses: PropTypes.object,
  valueStatement: PropTypes.object,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  cta1Url: PropTypes.string,
  cta1Alt: PropTypes.string,
  cta1Text: PropTypes.string,
  cta2Url: PropTypes.string,
  cta2Text: PropTypes.string,
  cta2AlternativeText: PropTypes.string,
  teamMemberTitle: PropTypes.string.isRequired,
  location: PropTypes.string,
  nmlsNumber: PropTypes.string,
  lmbNumber: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  image: PropTypes.object.isRequired,
  headingElement: PropTypes.string,
}

export { HeaderBioModule }
export default HeaderBioModule
