/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { parseISO, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from '../../grid'
import { Button } from '../../button'
import { Heading } from '../../typography/Heading'
import { Eyebrow } from '../../typography/Eyebrow'
import { UnstyledLink } from '../../typography/UnstyledLink'
import { Link } from '../../typography/Link'
import { Alert } from '../../alert'
import * as styles from './EventsModule.module.scss'

const EventsModule = ({ tags }) => {
  const [t, i18n] = useTranslation()
  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios({
          method: 'get',
          url: process.env.UPCOMING_EVENT_API_URL,
        })

        // Only allowing one tag at a time for now
        const oneDesiredTag = tags ? tags[0] : null

        if (oneDesiredTag !== null) {
          const filteredEvents = result.data.results.filter(event => {
            const eventTags = event.tags || []
            return eventTags.indexOf(oneDesiredTag) !== -1
          })
          setEvents(filteredEvents)
        } else {
          setEvents(result.data.results)
        }
        setEventsLoading(false)
      } catch (err) {
        setEventsLoading(false)
        setError(true)
      }
    }
    fetchData()
  }, [])

  if (error) {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Alert
              variant="error"
              title={t('EventsModule.errorAlertTitle', { defaultValue: 'Oops!' })}
            >
              {t('EventsModule.errorAlertMessage', {
                defaultValue:
                  "Looks like we're having some trouble retrieving our events right now. Please check back later.",
              })}
            </Alert>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!eventsLoading && events.length === 0) {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <p className={styles.emptyCta}>
              {t('EventsModule.emptyMessage', {
                defaultValue: "Sorry, we don't have an upcoming event in this category right now.",
              })}
            </p>

            <p className={styles.emptyCta}>
              <Link to="/events">{t('EventsModule.emptyCtaLabel', 'See all events')}</Link>
            </p>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        {events.map(event => {
          const { description, _logo, name, start, end, venue, url } = event
          const eventTags = event.tags || []
          const locale = eventTags.indexOf('spanish') !== -1 ? 'es-US' : 'en-US'
          const eventStart = parseISO(start.local)
          const dateStart = format(eventStart, 'LLLL d, uu')
          const timeBegin = format(eventStart, 'h:mm a')
          const timeEnd = format(parseISO(end.local), 'h:mm a')
          const mapLink = venue
            ? `https://www.google.com/maps/place/${venue.address.localized_address_display}`
            : null
          const location = venue ? `${venue.address.localized_address_display}` : null

          const lang = i18n.language !== locale ? locale : undefined

          return (
            <Col xs={12} md={6} key={event.id} className={styles.contentCard}>
              <div className={styles.cardContainer}>
                {/*
                  We're removing the image because Eventbrite does not provide a possibility to add
                  an alternative text label to images which makes the image inaccessible to our users.
                  After much consideration and countless discussions with the accessibility audit firm
                  we'd hired, we concluded that the best way forward is to remove the image for
                  the time being.
                */}
                {/* <div>
                  {logo && logo.original && <img src={logo.original.url} alt={name.text} />}
                </div> */}

                <div className={styles.contentContainer}>
                  <div>
                    {dateStart && <Eyebrow className={styles.tagline}>{dateStart}</Eyebrow>}

                    <Heading element="h2" size={3} className={styles.heading} lang={lang}>
                      {name.text}
                    </Heading>

                    <div className={styles.location}>
                      <p>
                        {timeBegin} - {timeEnd}
                      </p>

                      {venue && (
                        <p>
                          {venue.name}{' '}
                          <UnstyledLink
                            to={mapLink}
                            target="_blank"
                            className={styles.mapLink}
                            rel="noopener noreferrer"
                            aria-label={t(`EventsModule.viewMapLinkAriaLabel`, {
                              defaultValue: 'View {{venue.name}} on the map',
                              venue,
                            })}
                          >
                            {t('EventsModule.viewMapLinkLabel', '(View Map)')}
                          </UnstyledLink>
                        </p>
                      )}

                      {venue && <p>{location}</p>}
                    </div>

                    {description && (
                      <div className={styles.info}>
                        <p lang={lang}>{description.text}</p>
                      </div>
                    )}

                    {url && (
                      <div className={styles.buttonRow}>
                        <Button
                          alt={t(`EventsModule.registerLinkAriaLabel`, {
                            defaultValue: 'Register today for {{name.text}}',
                            name,
                          })}
                          url={url}
                          target="_blank"
                        >
                          {t('EventsModule.registerLinkLabel', { defaultValue: 'Register' })}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

EventsModule.propTypes = {
  richBody: PropTypes.object.isRequired,
  listType: PropTypes.string,
  tags: PropTypes.array,
}

export { EventsModule }
export default EventsModule
