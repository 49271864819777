import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { parseISO, format } from 'date-fns'
import { ImageCopyModule } from '../ImageCopy'
import { useTaggedEvent } from './hooks'

const UpcomingEventModule = ({
  imagePosition,
  imageBackgroundColor,
  fallbackTagline,
  fallbackHeadline,
  fallbackImage,
  fallbackRichBody,
  tags,
}) => {
  const [t, i18n] = useTranslation()

  const imageCopyOptions = {
    richTagline: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              marks: [],
              value: fallbackTagline,
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
    richHeadline: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              marks: [],
              value: fallbackHeadline,
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
    image: fallbackImage,
    imageCaption: null,
    richBody: fallbackRichBody,
    imagePosition,
    buttonCtaOne: t('UpcomingEvent.browseEventsButtonLabel', { defaultValue: 'Browse events' }),
    buttonUrlOne: '/events',
    buttonColorOne: 'dark',
    buttonTargetOne: '_self',
    buttonAltOne: 'Browse upcoming events',
    buttonCtaTwo: t('UpcomingEvent.visitBlogButtonLabel', { defaultValue: 'Visit our blog' }),
    buttonUrlTwo: 'https://blog.elevationscu.com',
    buttonColorTwo: 'light',
    buttonTargetTwo: '_blank',
    buttonAltTwo: 'Visit Elevations blog',
    imageBackgroundColor,
    disclaimer: null,
  }

  const [upcomingEvent, upcomingEventStatus] = useTaggedEvent(tags)
  if (upcomingEventStatus === 'loaded' && upcomingEvent) {
    const eventTags = upcomingEvent.tags || []
    const locale = eventTags.indexOf('spanish') !== -1 ? 'es-US' : 'en-US'
    const eventStart = parseISO(upcomingEvent.start.local)
    const dateStart = format(eventStart, 'EEEE, LLLL d')
    const timeBegin = format(eventStart, 'h:mm')
    const timeEnd = format(parseISO(upcomingEvent.end.local), 'h:mm aaaa')

    const lang = i18n.language !== locale ? locale : undefined

    imageCopyOptions.richTagline = {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              marks: [],
              value: t('UpcomingEvent.tagline', { defaultValue: 'Upcoming Event' }),
              data: {},
            },
          ],
          data: {},
        },
      ],
    }
    imageCopyOptions.richHeadline = {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              marks: [],
              value: upcomingEvent.name.text,
              data: {},
            },
          ],
          data: {
            lang,
          },
        },
      ],
    }
    imageCopyOptions.richBody = {
      nodeType: 'document',
      data: {},
      content: [],
    }

    if (upcomingEvent.summary) {
      imageCopyOptions.richBody.content.push({
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: upcomingEvent.summary,
            data: {},
          },
        ],
        data: {
          lang,
        },
      })
    }

    if (upcomingEvent.venue) {
      imageCopyOptions.richBody.content.push({
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [{ type: 'bold' }],
            value: `${dateStart}, ${timeBegin} - ${timeEnd}\n`,
            data: {},
          },
          {
            nodeType: 'text',
            marks: [],
            value: `${upcomingEvent.venue.name} `,
            data: {},
          },
          {
            nodeType: 'hyperlink',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: `(${t('UpcomingEvent.viewMapButtonLabel', { defaultValue: 'View Map' })})`,
                data: {},
              },
            ],
            data: {
              uri: `https://www.google.com/maps/place/${upcomingEvent.venue.address.localized_address_display}`,
            },
          },
        ],
        data: {},
      })

      imageCopyOptions.richBody.content.push({
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: `${upcomingEvent.venue.address.localized_address_display}`,
            data: {},
          },
        ],
        data: {},
      })
    } else {
      imageCopyOptions.richBody.content.push({
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [{ type: 'bold' }],
            value: `${dateStart}, ${timeBegin} - ${timeEnd}\n`,
            data: {},
          },
          {
            nodeType: 'text',
            marks: [],
            value: t('UpcomingEvent.hostedOnline', { defaultValue: 'Hosted online.' }),
            data: {},
          },
        ],
        data: {},
      })
    }

    /*
      We're removing the image because Eventbrite does not provide a possibility to add
      an alternative text label to images which makes the image inaccessible to our users.
      After much consideration and countless discussions with the accessibility audit firm
      we'd hired, we concluded that the best way forward is to remove the image for
      the time being.
    */
    // if (upcomingEvent.logo && upcomingEvent.logo.original) {
    //   imageCopyOptions.image = {
    //     title: t('UpcomingEvent.thumbnailTitle', { defaultValue: 'Upcoming Event preview' }),
    //     url: upcomingEvent.logo.original.url,
    //   }
    // }

    imageCopyOptions.buttonCtaOne = t('UpcomingEvent.registerButtonLabel', {
      defaultValue: 'Register today',
    })
    imageCopyOptions.buttonUrlOne = upcomingEvent.url
    imageCopyOptions.buttonColorOne = 'dark'
    imageCopyOptions.buttonTargetOne = '_blank'
    imageCopyOptions.buttonAltOne = t('UpcomingEvent.registerButtonAriaLabel', {
      defaultValue: `Register today for {{upcomingEvent.name.text}}`,
      upcomingEvent,
    })
    imageCopyOptions.buttonCtaTwo = t('UpcomingEvent.browseEventsButtonLabel', {
      defaultValue: 'Browse events',
    })
    imageCopyOptions.buttonUrlTwo = '/events'
    imageCopyOptions.buttonColorTwo = 'light'
    imageCopyOptions.buttonTargetTwo = '_self'
    imageCopyOptions.buttonAltTwo = t('UpcomingEvent.browseEventsButtonAriaLabel', {
      defaultValue: 'Browse events that Elevations has coming up',
    })
  }

  return <ImageCopyModule {...imageCopyOptions} />
}

UpcomingEventModule.propTypes = {
  imageBackgroundColor: PropTypes.oneOf(['tan', 'teal']),
  imagePosition: PropTypes.string.isRequired,
  fallbackTagline: PropTypes.string.isRequired,
  fallbackHeadline: PropTypes.string.isRequired,
  fallbackImage: PropTypes.object.isRequired,
  fallbackRichBody: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}

export { UpcomingEventModule }
export default UpcomingEventModule
