import { memo } from 'react'
import PropTypes from 'prop-types'

const LiveEngageButton = memo(({ buttonId, ...props }) => {
  if (!buttonId) {
    // eslint-disable-next-line no-console
    console.warn('buttonId is a required property')
    return null
  }

  // This component will only render once (thanks to the React.memo wrapper).
  // It will also only render a dummy button container. The bulk of the setup
  // will be carried out by the LiveEngage monitoring tag that is being added
  // in the PageMeta component. This monitoring tag is being complemented by
  // a small piece of code which resides in gatsby-browser.js
  return <div id={buttonId} {...props} />
})

LiveEngageButton.propTypes = {
  buttonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export { LiveEngageButton }
export default LiveEngageButton
