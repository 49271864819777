/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../grid'
import { RichText } from '../../rich-text'
import * as styles from './DisclaimerModule.module.scss'

const DisclaimerModule = ({ richBody, footnotes }) => {
  if (footnotes) {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <div className={styles.contentContainer}>
              <div className={styles.copy} data-qa="disclaimer-module-copy">
                {footnotes.map((footnote, key) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, react/no-array-index-key
                  <div id={`fn-${footnote.contentful_id}`} key={key} tabIndex="-1">
                    <RichText>{footnote.richBody.json}</RichText>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className={styles.contentContainer}>
            <div className={styles.copy} data-qa="disclaimer-module-copy">
              <RichText>{richBody}</RichText>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

DisclaimerModule.propTypes = {
  footnotes: PropTypes.array,
  richBody: PropTypes.object.isRequired,
}

export { DisclaimerModule }
export default DisclaimerModule
